import { substring } from "../fable-library.3.7.9/String.js";
import { disposeSafe, getEnumerator } from "../fable-library.3.7.9/Util.js";

export function fromFlatEntries(kvs) {
    const setProperty = (target_mut, key_mut, value_mut) => {
        setProperty:
        while (true) {
            const target = target_mut, key = key_mut, value = value_mut;
            const matchValue = key.indexOf(".") | 0;
            if (matchValue === -1) {
                target[key] = value;
            }
            else {
                const sepIdx = matchValue | 0;
                const topKey = substring(key, 0, sepIdx);
                const nestedKey = substring(key, sepIdx + 1);
                if (target[topKey] === undefined) {
                    target[topKey] = {};
                }
                target_mut = target[topKey];
                key_mut = nestedKey;
                value_mut = value;
                continue setProperty;
            }
            break;
        }
    };
    const target_1 = {};
    const enumerator = getEnumerator(kvs);
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            setProperty(target_1, forLoopVar[0], forLoopVar[1]);
        }
    }
    finally {
        disposeSafe(enumerator);
    }
    return target_1;
}

