import { singleton } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { ofArray } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Map.js";
import { map } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Array.js";
import { tokens } from "./Tokens.fs.js";

export function getMainNetTokens() {
    return singleton.Delay(() => singleton.Return(ofArray(map((t) => [t.address, t], tokens))));
}

export function getMainNetTokensNonAsync() {
    return ofArray(map((t) => [t.address, t], tokens));
}

export function convertToRealOnes(_arg1) {
    switch (_arg1) {
        case "7yjmFw6zrnMQFnZbfMm7YaFpGjDozEuFtWy1G847Cw7M": {
            return "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E";
        }
        case "7rVMEpCxGVk63TmjGD8WPUsNk7Ay4qoEXdG7aQ7ByrEV": {
            return "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E";
        }
        case "EGCCtLUtQ8AjuW8fJJzJc2QNuTXFa2X1Tk3vFHqt1Vt3": {
            return "So11111111111111111111111111111111111111112";
        }
        case "8vm43k8rLAejJWZE6Jn7QQwYkUhYzf31tSAwyuiz6Rzu": {
            return "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt";
        }
        case "36GT4YxELoB2myRdXMbq26MMykD95rCJBdTZL5KsRj2a": {
            return "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R";
        }
        case "525FPP6HX1gEEBG3zDkAHBjYxH3HVPKF4Gn32LKkDm7r": {
            return "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
        }
        default: {
            return _arg1;
        }
    }
}

