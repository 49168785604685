import { createRoot } from "react-dom/client";
import { createElement } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Router } from "./Components.fs.js";
import "./styles/global.scss";


export const container = document.getElementById("feliz-app");

export const root = createRoot(container);

root.render(createElement(Auth0Provider, {
    domain: "dev-x6tjmd7i.us.auth0.com",
    clientId: "8BwZJrBB4v5gDpqN19PTattz0MkR6PcE",
    redirectUri: window.location.origin,
    children: createElement(Router, null),
}));

