import { tryFind } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Map.js";
import { convertToRealOnes } from "./SolanaTokenRegistry.fs.js";
import { PublicKey_Value_Z1B21ACD5 } from "../Domain/Types/Common.fs.js";
import { some } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Option.js";
import { singleton } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { encodeU64, encodeString2NoLength, encodePublicKey2 } from "./Layout.fs.js";
import { bn, findProgramAddress } from "./Solana.fs.js";
import { toInt } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Long.js";

export function fetchToken(key, tokens) {
    return tryFind(convertToRealOnes(PublicKey_Value_Z1B21ACD5(key)), tokens);
}

export function fetchInjectiveToken(address, tokens) {
    return tryFind(address, tokens);
}

export function getBugBountyUserPDA(bugBountyAccount, walletPublicKey, bugBountyProgram) {
    console.log(some("getBugBountyUserPDA"));
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(bugBountyAccount);
        const b2 = encodePublicKey2(walletPublicKey);
        const b3 = encodeString2NoLength("remark-counter");
        console.log(some("b1: " + b1.toLocaleString()));
        console.log(some("b2: " + b2.toLocaleString()));
        console.log(some("b3: " + b3.toLocaleString()));
        return singleton.Bind(findProgramAddress([b1, b2, b3], bugBountyProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getBugBountyRemarkPDA(bugBountyAccount, walletPublicKey, id, bugBountyProgram) {
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(bugBountyAccount);
        console.log(some("remark b1: " + b1.toLocaleString()));
        const b2 = encodePublicKey2(walletPublicKey);
        console.log(some("remark b2: " + b2.toLocaleString()));
        const b3 = encodeU64(bn(toInt(id) >>> 0));
        console.log(some("remark b3: " + b3.toLocaleString()));
        return singleton.Bind(findProgramAddress([b1, b2], bugBountyProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getDevNetProfilePDA(walletPublicKey, profileProgram) {
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(walletPublicKey);
        const b2 = encodeString2NoLength("devnet-profile");
        return singleton.Bind(findProgramAddress([b2, b1], profileProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getFaucetPDA(faucetProgram) {
    return singleton.Delay(() => {
        const b1 = encodeString2NoLength("devnet-profile");
        return singleton.Bind(findProgramAddress([b1], faucetProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getMintAuthorityPDA(faucetProgram, mint) {
    return singleton.Delay(() => {
        const b1 = encodePublicKey2(mint);
        return singleton.Bind(findProgramAddress([b1], faucetProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function getProfilePDA(profileProgram, wallet) {
    return singleton.Delay(() => {
        const b1 = encodeString2NoLength("devnet-profile");
        const b2 = encodePublicKey2(wallet);
        return singleton.Bind(findProgramAddress([b1, b2], profileProgram), (_arg1) => singleton.Return(_arg1));
    });
}

export function String_contains(v, s) {
    return s.indexOf(v) >= 0;
}

export function String_toLower(s) {
    return s.toLowerCase();
}

