import { some } from "./fable_modules/fable-library.3.7.9/Option.js";
import { Cmd_ofSub } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { catchAsync, startImmediate } from "./fable_modules/fable-library.3.7.9/Async.js";
import { singleton } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.3.7.9/String.js";

export const isDevelopment = false;

export function Log_developmentError(error) {
    if (isDevelopment) {
        console.error(some(error));
    }
}

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(catchAsync(operation), (_arg1) => {
            if (_arg1.tag === 1) {
                Log_developmentError(_arg1.fields[0]);
                return singleton.Zero();
            }
            else {
                dispatch(_arg1.fields[0]);
                return singleton.Zero();
            }
        })));
    });
}

export function Config_variableOrDefault(key, defaultValue) {
    const foundValue = process.env[key] ? process.env[key] : '';
    if (isNullOrWhiteSpace(foundValue)) {
        return defaultValue;
    }
    else {
        return foundValue;
    }
}

