import { Record, Union } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Types.js";
import { list_type, record_type, tuple_type, option_type, union_type } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Reflection.js";
import { InjectiveHolding$reflection } from "./InjectiveHolding.fs.js";
import { InjectiveProduct$reflection } from "./InjectiveProduct.fs.js";
import { Option$reflection } from "./InjectiveOption.fs.js";
import { Rfq$reflection } from "./InjectiveRfq.fs.js";
import { DelegationData$reflection } from "./InjectiveDelegationData.fs.js";
import { addSeconds } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/DateOffset.js";
import { toNumber } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Long.js";
import { Paging$1$reflection } from "./Common.fs.js";

export class InjectiveHoldingBackendSortField extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HoldingName", "ProductKind", "HoldingId", "FairPrice", "StrikePricePercent", "StrikePrice", "Maturity", "EndCollection", "TimeToEndCollection", "OptionExpiry", "BaseCurrency", "QuoteCurrency", "TotalDepositedAmount", "AmountLocked", "ProductStarted", "NbOption", "NbOptionRemaining", "OptionsRemaining", "CollateralCollected", "DelegationStatus", "DelegationTime", "UndelegationTime"];
    }
}

export function InjectiveHoldingBackendSortField$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveBackofficeSharedType.InjectiveHoldingBackendSortField", [], InjectiveHoldingBackendSortField, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class DelegationStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Na", "NotDelegatedYet", "Delegated", "Undelegated", "Withdrawn"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Not Delegated Yet";
            }
            case 2: {
                return "Delegated";
            }
            case 3: {
                return "Undelegated";
            }
            case 4: {
                return "Withdrawn";
            }
            default: {
                return "NA";
            }
        }
    }
}

export function DelegationStatus$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveBackofficeSharedType.DelegationStatus", [], DelegationStatus, () => [[], [], [], [], []]);
}

export class BackOfficeInjectiveHoldingProductOptionRfq extends Record {
    constructor(Holding, Product, Option, Rfq, Delegation) {
        super();
        this.Holding = Holding;
        this.Product = Product;
        this.Option = Option;
        this.Rfq = Rfq;
        this.Delegation = Delegation;
    }
}

export function BackOfficeInjectiveHoldingProductOptionRfq$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveBackofficeSharedType.BackOfficeInjectiveHoldingProductOptionRfq", [], BackOfficeInjectiveHoldingProductOptionRfq, () => [["Holding", InjectiveHolding$reflection()], ["Product", InjectiveProduct$reflection()], ["Option", option_type(Option$reflection())], ["Rfq", option_type(Rfq$reflection())], ["Delegation", tuple_type(DelegationStatus$reflection(), option_type(DelegationData$reflection()))]]);
}

export function BackOfficeInjectiveHoldingProductOptionRfq__GetOptionExpiry(this$) {
    const matchValue = this$.Option;
    if (matchValue == null) {
        return addSeconds(this$.Holding.EndCollection, toNumber(this$.Product.Duration));
    }
    else {
        return matchValue.OptionExpiry;
    }
}

export class InjectiveProductsPage extends Record {
    constructor(Products, Paging) {
        super();
        this.Products = Products;
        this.Paging = Paging;
    }
}

export function InjectiveProductsPage$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveBackofficeSharedType.InjectiveProductsPage", [], InjectiveProductsPage, () => [["Products", list_type(BackOfficeInjectiveHoldingProductOptionRfq$reflection())], ["Paging", Paging$1$reflection(option_type(InjectiveHoldingBackendSortField$reflection()))]]);
}

