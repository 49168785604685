import counter$002Emodule from "./styles/counter.module.scss";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.62.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "./fable_modules/fable-library.3.7.9/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.62.0/./Interop.fs.js";
import { tail, head, isEmpty, singleton as singleton_2, ofArray } from "./fable_modules/fable-library.3.7.9/List.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { ApiHandler$1, useApi } from "./Server.fs.js";
import { singleton } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { map, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.9/Seq.js";
import { Union, toString } from "./fable_modules/fable-library.3.7.9/Types.js";
import { union_type } from "./fable_modules/fable-library.3.7.9/Reflection.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.9/String.js";
import { hangfireFullUrl } from "../../shared/Api.fs.js";
import { RouterModule_router, RouterModule_urlSegments, RouterModule_nav } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import js_cookie from "js-cookie";
import { InjectiveProducts } from "./InjectiveProducts.fs.js";
import { ClientStatsList } from "./ClientStats.fs.js";
import { SettingsPage } from "./SettingsPage.fs.js";
import { FlexProductsList } from "./FlexProducts.fs.js";

const stylesheet = counter$002Emodule;

export function StyledCounter() {
    let elems;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const count = patternInput[0] | 0;
    return createElement("div", createObj(ofArray([["className", stylesheet["main-container"]], (elems = [createElement("h1", {
        children: [count],
    }), createElement("button", {
        className: stylesheet["increment-button"],
        children: "Increment",
        onClick: (_arg1) => {
            patternInput[1](count + 1);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function BulmaCounter() {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const count = patternInput[0] | 0;
    const children = ofArray([createElement("h1", {
        className: "title",
        children: count,
    }), createElement("button", {
        className: "button",
        children: "Increment",
        onClick: (_arg1) => {
            patternInput[1](count + 1);
        },
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ServerCounter(serverCounterInputProps) {
    const user = serverCounterInputProps.user;
    const auth = useAuth0();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(useReact_useCallback_93353E(() => useApi(new ApiHandler$1(() => auth.getIdTokenClaims(), (server) => singleton.Delay(() => singleton.Bind(server.Counter(), (_arg1) => singleton.Bind(server.UserRoles(), (_arg2) => singleton.Return([_arg1, _arg2])))))))(), []);
    switch (data.tag) {
        case 1: {
            return createElement("h1", {
                children: ["Loading"],
            });
        }
        case 3: {
            const value_1 = data.fields[0].message;
            return createElement("p", {
                children: [value_1],
            });
        }
        case 2: {
            const children = toList(delay(() => append(singleton_1(createElement("h1", {
                children: [data.fields[0][0].Value],
            })), delay(() => map((role) => {
                const value_3 = toString(role);
                return createElement("p", {
                    children: [value_3],
                });
            }, data.fields[0][1])))));
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }
        default: {
            return null;
        }
    }
}

export class BackOfficePagesList extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Products", "InjectiveProducts", "Stats", "SettingsRoute"];
    }
}

export function BackOfficePagesList$reflection() {
    return union_type("Components.BackOfficePagesList", [], BackOfficePagesList, () => [[], [], [], []]);
}

export function HomePage(homePageInputProps) {
    let elems_2, elems;
    const page = homePageInputProps.page;
    const auth = useAuth0();
    const matchValue = auth.user;
    let pattern_matching_result;
    if (matchValue == null) {
        if (auth.isLoading) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            if (matchValue != null) {
                const user = matchValue;
                return createElement("div", createObj(ofArray([["style", {
                    marginBottom: 20,
                    padding: 20,
                }], (elems_2 = toList(delay(() => {
                    let value_14;
                    return append(singleton_1((value_14 = toText(printf("Exotic Markets - Backoffice")), createElement("h3", {
                        children: [value_14],
                    }))), delay(() => {
                        let value_15, arg10;
                        return append(singleton_1((value_15 = ((arg10 = user.name, toText(printf("Welcome %s"))(arg10))), createElement("h5", {
                            children: [value_15],
                        }))), delay(() => {
                            let elems_1;
                            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                                marginTop: 20,
                                marginBottom: 20,
                            }], (elems_1 = [createElement("a", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Hangfire Dashboard",
                                href: hangfireFullUrl,
                            }), createElement("a", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Solana Products",
                                href: "#/SolanaProducts",
                                onClick: (_arg2) => {
                                    RouterModule_nav(singleton_2("SolanaProducts"), 1, 1);
                                },
                            }), createElement("a", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Injective Products",
                                href: "#/InjectiveProducts",
                                onClick: (_arg3) => {
                                    RouterModule_nav(singleton_2("InjectiveProducts"), 1, 1);
                                },
                            }), createElement("a", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Client Stats",
                                href: "#/ClientStats",
                                onClick: (_arg4) => {
                                    RouterModule_nav(singleton_2("ClientStats"), 1, 1);
                                },
                            }), createElement("a", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Settings",
                                href: "#/Settings",
                                onClick: (_arg5) => {
                                    RouterModule_nav(singleton_2("Settings"), 1, 1);
                                },
                            }), createElement("button", {
                                style: {
                                    paddingRight: 10,
                                },
                                children: "Logout",
                                onClick: (_arg6) => {
                                    js_cookie.remove("access_token");
                                    auth.logout();
                                },
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => ((page.tag === 1) ? singleton_1(createElement(InjectiveProducts, null)) : ((page.tag === 2) ? singleton_1(createElement(ClientStatsList, {
                                user: user,
                            })) : ((page.tag === 3) ? singleton_1(createElement(SettingsPage, {
                                user: user,
                            })) : singleton_1(createElement(FlexProductsList, {
                                user: user,
                            })))))));
                        }));
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
            }
            else {
                return createElement("div", createObj(ofArray([["style", {
                    marginTop: 20,
                    textAlign: "center",
                }], (elems = [createElement("h1", {
                    children: ["Exotic Markets - Backoffice"],
                }), createElement("button", {
                    children: "Login",
                    onClick: (_arg1) => {
                        auth.loginWithPopup();
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
            }
        }
    }
}

export function Router() {
    let elements;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => ((!isEmpty(currentUrl)) ? ((head(currentUrl) === "SolanaProducts") ? (isEmpty(tail(currentUrl)) ? singleton_1(createElement(HomePage, {
        page: new BackOfficePagesList(0),
    })) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "InjectiveProducts") ? (isEmpty(tail(currentUrl)) ? singleton_1(createElement(HomePage, {
        page: new BackOfficePagesList(1),
    })) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "ClientStats") ? (isEmpty(tail(currentUrl)) ? singleton_1(createElement(HomePage, {
        page: new BackOfficePagesList(2),
    })) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "Settings") ? (isEmpty(tail(currentUrl)) ? singleton_1(createElement(HomePage, {
        page: new BackOfficePagesList(3),
    })) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "styled") ? (isEmpty(tail(currentUrl)) ? singleton_1(createElement(StyledCounter, null)) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))) : singleton_1(createElement("h1", {
        children: ["Not found"],
    }))))))) : singleton_1(createElement(HomePage, {
        page: new BackOfficePagesList(1),
    }))))), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

