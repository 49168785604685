import { useAuth0 } from "@auth0/auth0-react";
import { useReact_useEffect_Z101E1A95, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.62.0/React.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.7.9/Async.js";
import { singleton } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { utcNow } from "./fable_modules/fable-library.3.7.9/Date.js";
import { Settings } from "../../../lib/Domain/Types/Settings.fs.js";
import { ApiHandler$1, useApi } from "./Server.fs.js";
import { createElement } from "react";
import { Col, Row } from "../../../lib/CustomComponents/CustomComponents.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { ofArray, empty, singleton as singleton_1 } from "./fable_modules/fable-library.3.7.9/List.js";
import { createObj } from "./fable_modules/fable-library.3.7.9/Util.js";
import { join } from "./fable_modules/fable-library.3.7.9/String.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "./fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.62.0/./Interop.fs.js";
import { SolanaEnvironment } from "../../../lib/Domain/Types/Common.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";

export function SettingsComp(settingsCompInputProps) {
    let elems;
    const environment = settingsCompInputProps.environment;
    const settings = settingsCompInputProps.settings;
    const auth = useAuth0();
    const patternInput = useFeliz_React__React_useState_Static_1505(settings.HideAllProducts);
    const hideAllProducts = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(settings.FreezeAllDeposits);
    const freezeAllDeposits = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(settings.ExcludedProductsPublicKeys);
    const excludedProducts = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(settings.WarningMessage);
    const warningMessage = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(settings.GeneralMessage);
    const generalMessage = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setIsSaving = patternInput_5[1];
    const isSaving = patternInput_5[0];
    const saveSettingsCallback = useReact_useCallback_93353E(() => {
        startImmediate((setIsSaving(true), singleton.Delay(() => {
            let settings_1;
            let Username;
            const matchValue = auth.user;
            if (matchValue == null) {
                Username = "";
            }
            else {
                const u = matchValue;
                Username = u.name;
            }
            settings_1 = (new Settings(hideAllProducts, excludedProducts, utcNow(), Username, warningMessage, generalMessage, freezeAllDeposits));
            return singleton.Bind(useApi(new ApiHandler$1(() => auth.getIdTokenClaims(), (server) => singleton.Delay(() => singleton.Bind(server.SaveSettings(environment, settings_1), () => singleton.Return())))), () => {
                setIsSaving(false);
                return singleton.Zero();
            });
        })));
    }, [excludedProducts, hideAllProducts, warningMessage, freezeAllDeposits, generalMessage]);
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-l"]),
        children: singleton_1(createElement(Col, {
            classes: empty(),
            children: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule["margin-m-top"]])], (elems = [createElement(Row, {
                classes: singleton_1(utils$002Emodule["gap-xl"]),
                children: ofArray([createElement(Col, {
                    classes: empty(),
                    children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Hide all products"], ["control", MuiHelpers_createElement(Checkbox, [["disabled", isSaving], ["checked", hideAllProducts], ["onChange", (e) => {
                        patternInput[1](Browser_Types_Event__Event_get_Checked(e));
                    }]])]])),
                }), createElement(Col, {
                    classes: empty(),
                    children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Freeze deposits on all products"], ["control", MuiHelpers_createElement(Checkbox, [["disabled", isSaving], ["checked", freezeAllDeposits], ["onChange", (e_1) => {
                        patternInput_1[1](Browser_Types_Event__Event_get_Checked(e_1));
                    }]])]])),
                })]),
            }), createElement(Row, {
                classes: singleton_1(utils$002Emodule["margin-m-top"]),
                children: singleton_1(createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement("label", {
                        children: "Enter the public keys of products to be excluded from products list (comma seperated):",
                    }), createElement("textarea", {
                        disabled: isSaving,
                        rows: 5,
                        className: join(" ", []),
                        placeholder: "Comma seperated Publickeys",
                        defaultValue: excludedProducts,
                        onChange: (ev) => {
                            patternInput_2[1](ev.target.value);
                        },
                    })]),
                })),
            }), createElement(Row, {
                classes: singleton_1(utils$002Emodule["margin-m-top"]),
                children: singleton_1(createElement(Col, {
                    classes: singleton_1(utils$002Emodule["full-width"]),
                    children: ofArray([createElement("label", {
                        children: "Warning Message (Banner):",
                    }), createElement("textarea", {
                        disabled: isSaving,
                        rows: 3,
                        className: join(" ", []),
                        placeholder: "Warning Message",
                        defaultValue: warningMessage,
                        onChange: (ev_1) => {
                            patternInput_3[1](ev_1.target.value);
                        },
                    })]),
                })),
            }), createElement(Row, {
                classes: singleton_1(utils$002Emodule["margin-m-top"]),
                children: singleton_1(createElement(Col, {
                    classes: singleton_1(utils$002Emodule["full-width"]),
                    children: ofArray([createElement("label", {
                        children: "General Message (Green Banner):",
                    }), createElement("textarea", {
                        disabled: isSaving,
                        rows: 3,
                        className: join(" ", []),
                        placeholder: "General Message",
                        defaultValue: generalMessage,
                        onChange: (ev_2) => {
                            patternInput_4[1](ev_2.target.value);
                        },
                    })]),
                })),
            }), createElement(Row, {
                classes: singleton_1(utils$002Emodule["margin-m-top"]),
                children: singleton_1(createElement(Col, {
                    classes: empty(),
                    children: singleton_1(createElement("button", {
                        disabled: isSaving,
                        className: join(" ", [utils$002Emodule["margin-l-top"]]),
                        children: isSaving ? "Saving..." : "Save Settings",
                        onClick: (_arg1_1) => {
                            saveSettingsCallback();
                        },
                    })),
                })),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
        })),
    });
}

export function SettingsPage(settingsPageInputProps) {
    let elems;
    const user = settingsPageInputProps.user;
    const environment = useFeliz_React__React_useState_Static_1505(new SolanaEnvironment(1))[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const auth = useAuth0();
    useReact_useEffect_Z101E1A95(useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => useApi(new ApiHandler$1(() => auth.getIdTokenClaims(), (server) => singleton.Delay(() => singleton.Bind(server.GetSettings(environment), (_arg1) => singleton.Return(_arg1))))), patternInput_1[1]), [environment]);
    switch (data.tag) {
        case 1: {
            return createElement("h1", {
                children: ["Loading"],
            });
        }
        case 3: {
            const value_1 = data.fields[0].message;
            return createElement("p", {
                children: [value_1],
            });
        }
        case 2: {
            return createElement("div", createObj(singleton_1((elems = [createElement("h1", {
                children: ["Settings"],
            }), createElement(SettingsComp, {
                settings: data.fields[0],
                environment: environment,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
        }
        default: {
            return null;
        }
    }
}

