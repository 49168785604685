import { Union, Record } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Types.js";
import { uint32_type, option_type, class_type, union_type, record_type, bool_type, string_type } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Reflection.js";
import { nativeOptionGenericBalanceDecoder, FPDECIMAL_ONE, nativeDenomDecoder, OptionGenericBalance$reflection, Denom$reflection } from "./Common.fs.js";
import { InjectiveProductKind, InjectiveProductKind$reflection } from "./InjectiveProduct.fs.js";
import { toInt64, toDecimal, get_Zero } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/BigInt.js";
import { keyValuePairs, fromString, uint32, map, oneOf, bigint, bool, string, object } from "../../../backoffice/client/src/fable_modules/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Util.js";
import { parse, op_Multiply } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Decimal.js";
import DateOffset from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/DateOffset.js";
import { fromInteger, op_Division, toNumber } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Long.js";
import { toArray, ofArray } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/List.js";
import { map as map_1 } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Option.js";
import { Result_Map } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Choice.js";
import { map as map_2 } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Array.js";

export class AddressPosition extends Record {
    constructor(Address, Validated) {
        super();
        this.Address = Address;
        this.Validated = Validated;
    }
}

export function AddressPosition$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.AddressPosition", [], AddressPosition, () => [["Address", string_type], ["Validated", bool_type]]);
}

export class OptionPositionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Address", "Token"];
    }
}

export function OptionPositionType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveOption.OptionPositionType", [], OptionPositionType, () => [[["Item", AddressPosition$reflection()]], []]);
}

export class Option extends Record {
    constructor(Address, Creator, Base, Quote, BaseOracle, QuoteOracle, OptionKind, Strike, OptionExpiry, PositionType, CollateralAmount, NbOption, SettlementPrice, RemainingCollateral, CurrentCollateralAmount, CollateralCollected, NbOptionRemaining, SettlementPriceThreshold) {
        super();
        this.Address = Address;
        this.Creator = Creator;
        this.Base = Base;
        this.Quote = Quote;
        this.BaseOracle = BaseOracle;
        this.QuoteOracle = QuoteOracle;
        this.OptionKind = OptionKind;
        this.Strike = Strike;
        this.OptionExpiry = OptionExpiry;
        this.PositionType = PositionType;
        this.CollateralAmount = CollateralAmount;
        this.NbOption = NbOption;
        this.SettlementPrice = SettlementPrice;
        this.RemainingCollateral = RemainingCollateral;
        this.CurrentCollateralAmount = CurrentCollateralAmount;
        this.CollateralCollected = CollateralCollected;
        this.NbOptionRemaining = NbOptionRemaining;
        this.SettlementPriceThreshold = SettlementPriceThreshold;
    }
}

export function Option$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.Option", [], Option, () => [["Address", string_type], ["Creator", string_type], ["Base", Denom$reflection()], ["Quote", Denom$reflection()], ["BaseOracle", string_type], ["QuoteOracle", string_type], ["OptionKind", InjectiveProductKind$reflection()], ["Strike", class_type("System.Decimal")], ["OptionExpiry", class_type("System.DateTimeOffset")], ["PositionType", OptionPositionType$reflection()], ["CollateralAmount", OptionGenericBalance$reflection()], ["NbOption", class_type("System.Decimal")], ["SettlementPrice", option_type(class_type("System.Decimal"))], ["RemainingCollateral", class_type("System.Decimal")], ["CurrentCollateralAmount", class_type("System.Decimal")], ["CollateralCollected", bool_type], ["NbOptionRemaining", class_type("System.Decimal")], ["SettlementPriceThreshold", uint32_type]]);
}

export function Option__GetCollateralAmount(this$) {
    const matchValue = this$.CollateralAmount;
    if (matchValue.tag === 0) {
        return matchValue.fields[0].Amount;
    }
    else {
        return get_Zero;
    }
}

export class OptionRaw extends Record {
    constructor(Creator, Base, Quote, BaseOracle, QuoteOracle, OptionKind, Strike, OptionExpiry, PositionType, CollateralAmount, NbOption, SettlementPrice, RemainingCollateral, CurrentCollateralAmount, CollateralCollected, NbOptionRemaining, SettlementPriceThreshold) {
        super();
        this.Creator = Creator;
        this.Base = Base;
        this.Quote = Quote;
        this.BaseOracle = BaseOracle;
        this.QuoteOracle = QuoteOracle;
        this.OptionKind = OptionKind;
        this.Strike = Strike;
        this.OptionExpiry = OptionExpiry;
        this.PositionType = PositionType;
        this.CollateralAmount = CollateralAmount;
        this.NbOption = NbOption;
        this.SettlementPrice = SettlementPrice;
        this.RemainingCollateral = RemainingCollateral;
        this.CurrentCollateralAmount = CurrentCollateralAmount;
        this.CollateralCollected = CollateralCollected;
        this.NbOptionRemaining = NbOptionRemaining;
        this.SettlementPriceThreshold = SettlementPriceThreshold;
    }
}

export function OptionRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveOption.OptionRaw", [], OptionRaw, () => [["Creator", string_type], ["Base", Denom$reflection()], ["Quote", Denom$reflection()], ["BaseOracle", string_type], ["QuoteOracle", string_type], ["OptionKind", InjectiveProductKind$reflection()], ["Strike", class_type("System.Decimal")], ["OptionExpiry", class_type("System.DateTimeOffset")], ["PositionType", OptionPositionType$reflection()], ["CollateralAmount", OptionGenericBalance$reflection()], ["NbOption", class_type("System.Decimal")], ["SettlementPrice", option_type(class_type("System.Decimal"))], ["RemainingCollateral", class_type("System.Decimal")], ["CurrentCollateralAmount", class_type("System.Decimal")], ["CollateralCollected", bool_type], ["NbOptionRemaining", class_type("System.Decimal")], ["SettlementPriceThreshold", uint32_type]]);
}

export function OptionRaw__ToOption_Z721C83C5(this$, address) {
    return new Option(address, this$.Creator, this$.Base, this$.Quote, this$.BaseOracle, this$.QuoteOracle, this$.OptionKind, this$.Strike, this$.OptionExpiry, this$.PositionType, this$.CollateralAmount, this$.NbOption, this$.SettlementPrice, this$.RemainingCollateral, this$.CurrentCollateralAmount, this$.CollateralCollected, this$.NbOptionRemaining, this$.SettlementPriceThreshold);
}

export const optionAddresssPositionDecoder = (path_3) => ((v_2) => object((get$_1) => {
    let objectArg_2;
    return new OptionPositionType(0, (objectArg_2 = get$_1.Required, objectArg_2.Field("address", (path_2, v_1) => object((get$) => {
        let objectArg, objectArg_1;
        return new AddressPosition((objectArg = get$.Required, objectArg.Field("address", string)), (objectArg_1 = get$.Required, objectArg_1.Field("validated", bool)));
    }, path_2, v_1))));
}, path_3, v_2));

export function optionTokenPositionDecoder(s) {
    if (s === "token") {
        return new OptionPositionType(1);
    }
    else {
        throw (new Error("Unknown position type"));
    }
}

export const injectiveOptionRawDecoder = (path_15) => ((v) => object((get$) => {
    let objectArg, objectArg_1, objectArg_2, objectArg_3, objectArg_4, x, objectArg_5, objectArg_6, objectArg_7, objectArg_8, objectArg_9, objectArg_10, objectArg_11, objectArg_12, objectArg_13, objectArg_14, objectArg_15, objectArg_16;
    return new OptionRaw((objectArg = get$.Required, objectArg.Field("creator", string)), (objectArg_1 = get$.Required, objectArg_1.Field("base", uncurry(2, nativeDenomDecoder))), (objectArg_2 = get$.Required, objectArg_2.Field("quote", uncurry(2, nativeDenomDecoder))), (objectArg_3 = get$.Required, objectArg_3.Field("base_oracle", string)), (objectArg_4 = get$.Required, objectArg_4.Field("quote_oracle", string)), (x = ((objectArg_5 = get$.Required, objectArg_5.Field("option_kind", string))), (x === "call") ? (new InjectiveProductKind(0)) : ((x === "put") ? (new InjectiveProductKind(1)) : (() => {
        throw (new Error("Error in deserializing injective product kind"));
    })())), op_Multiply(parse((objectArg_6 = get$.Required, objectArg_6.Field("strike", string))), toDecimal(FPDECIMAL_ONE)), DateOffset(toNumber(op_Division(toInt64((objectArg_7 = get$.Required, objectArg_7.Field("option_expiry", bigint))), fromInteger(1000000000, false, 2))) * 1000, 0), (objectArg_8 = get$.Required, objectArg_8.Field("position_type", (path_8, value_8) => oneOf(ofArray([(path_7) => ((value_7) => map(optionTokenPositionDecoder, string, path_7, value_7)), optionAddresssPositionDecoder]), path_8, value_8))), (objectArg_9 = get$.Required, objectArg_9.Field("collateral_amount", uncurry(2, nativeOptionGenericBalanceDecoder))), op_Multiply(parse((objectArg_10 = get$.Required, objectArg_10.Field("nb_option", string))), toDecimal(FPDECIMAL_ONE)), map_1((p) => op_Multiply(parse(p), toDecimal(FPDECIMAL_ONE)), (objectArg_11 = get$.Optional, objectArg_11.Field("settlement_price", string))), op_Multiply(parse((objectArg_12 = get$.Required, objectArg_12.Field("remaining_collateral", string))), toDecimal(FPDECIMAL_ONE)), op_Multiply(parse((objectArg_13 = get$.Required, objectArg_13.Field("current_collateral_amount", string))), toDecimal(FPDECIMAL_ONE)), (objectArg_14 = get$.Required, objectArg_14.Field("collateral_collected", bool)), op_Multiply(parse((objectArg_15 = get$.Required, objectArg_15.Field("nb_option_remaining", string))), toDecimal(FPDECIMAL_ONE)), (objectArg_16 = get$.Required, objectArg_16.Field("settlement_price_threshold_seconds", uncurry(2, uint32))));
}, path_15, v));

export function deserializeOption(address, data) {
    const raw = fromString(uncurry(2, injectiveOptionRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in option deserialization"));
    }
    else {
        return OptionRaw__ToOption_Z721C83C5(raw.fields[0], address);
    }
}

export function deserializeOptionArray(jsonString) {
    const res = Result_Map(toArray, fromString((path, value) => keyValuePairs(uncurry(2, injectiveOptionRawDecoder), path, value), jsonString));
    if (res.tag === 1) {
        throw (new Error("failed in option array deserialization"));
    }
    else {
        return map_2((tupledArg) => OptionRaw__ToOption_Z721C83C5(tupledArg[1], tupledArg[0]), res.fields[0]);
    }
}

