import { join, padLeft } from "./fable_modules/fable-library.3.7.9/String.js";
import { int32ToString } from "./fable_modules/fable-library.3.7.9/Util.js";
import { fromSeconds, seconds as seconds_2, minutes, hours, days } from "./fable_modules/fable-library.3.7.9/TimeSpan.js";
import { toString } from "./fable_modules/fable-library.3.7.9/Date.js";
import { createElement } from "react";
import utils$002Emodule from "./styles/utils.module.scss";
import { useCountdown } from "../../../lib/CustomHooks/CustomHooks.fs.js";

export function formatTime(duration) {
    const pad = (x) => padLeft(int32ToString(x), 2, "0");
    if (duration <= 0) {
        return "Expired";
    }
    else {
        const matchValue = [days(duration), hours(duration), minutes(duration), seconds_2(duration)];
        if (duration <= 0) {
            return "Expired";
        }
        else if (matchValue[0] === 0) {
            if (matchValue[1] === 0) {
                if (matchValue[2] === 0) {
                    return `${pad(matchValue[3])}s`;
                }
                else {
                    return `${pad(matchValue[2])}m ${pad(matchValue[3])}s`;
                }
            }
            else {
                return `${pad(matchValue[1])}h ${pad(matchValue[2])}m ${pad(matchValue[3])}s`;
            }
        }
        else {
            return `${pad(matchValue[0])}d ${pad(matchValue[1])}h ${pad(matchValue[2])}m ${pad(matchValue[3])}s`;
        }
    }
}

export function formatDate(date) {
    return toString(date, "yyyy-MM-dd HH:mm");
}

export function DisplayTime(displayTimeInputProps) {
    const time = formatTime(fromSeconds(displayTimeInputProps.seconds));
    return createElement("div", {
        className: join(" ", [utils$002Emodule["text-high-risk"]]),
        children: `${time}`,
    });
}

export function Time(timeInputProps) {
    const classes = timeInputProps.classes;
    const time = formatTime(fromSeconds(timeInputProps.seconds));
    return createElement("div", {
        className: join(" ", classes),
        children: `${time}`,
    });
}

export function TimeCountdown(timeCountdownInputProps) {
    const seconds_1 = useCountdown(timeCountdownInputProps.seconds) | 0;
    return createElement(Time, {
        seconds: seconds_1,
        classes: [],
    });
}

