import { toString, Union, Record } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Types.js";
import { option_type, union_type, record_type, tuple_type, class_type, string_type } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Reflection.js";
import { nativeDenomDecoder, rfqGenericBalanceDecoder, Denom$reflection, RfqGenericBalance$reflection } from "./Common.fs.js";
import { fromParts } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Decimal.js";
import { toInt64, toDecimal } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/BigInt.js";
import { keyValuePairs, fromString, map, oneOf, option, bigint, string, tuple2, object } from "../../../backoffice/client/src/fable_modules/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Util.js";
import { printf, toFail } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/String.js";
import DateOffset from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/DateOffset.js";
import { fromInteger, op_Division, toNumber } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Long.js";
import { toArray, ofArray } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/List.js";
import { Result_Map } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Choice.js";
import { map as map_1 } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Array.js";

export class ClosedStatus extends Record {
    constructor(SelectedBid) {
        super();
        this.SelectedBid = SelectedBid;
    }
}

export function ClosedStatus$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.ClosedStatus", [], ClosedStatus, () => [["SelectedBid", tuple_type(string_type, class_type("System.Numerics.BigInteger"))]]);
}

export class Status extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Open", "Closed"];
    }
}

export function Status$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveRfq.Status", [], Status, () => [[], [["Item", ClosedStatus$reflection()]]]);
}

export class Selection$ extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Highest", "Manual"];
    }
}

export function Selection$$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveRfq.Selection", [], Selection$, () => [[], []]);
}

export class Rfq extends Record {
    constructor(Address, Creator, Asset, BidToken, MinBid, BidEnd, SelectionEnd, SelectionType, Status, HighestBid) {
        super();
        this.Address = Address;
        this.Creator = Creator;
        this.Asset = Asset;
        this.BidToken = BidToken;
        this.MinBid = MinBid;
        this.BidEnd = BidEnd;
        this.SelectionEnd = SelectionEnd;
        this.SelectionType = SelectionType;
        this.Status = Status;
        this.HighestBid = HighestBid;
    }
}

export function Rfq$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.Rfq", [], Rfq, () => [["Address", string_type], ["Creator", string_type], ["Asset", RfqGenericBalance$reflection()], ["BidToken", Denom$reflection()], ["MinBid", class_type("System.Numerics.BigInteger")], ["BidEnd", class_type("System.DateTimeOffset")], ["SelectionEnd", class_type("System.DateTimeOffset")], ["SelectionType", Selection$$reflection()], ["Status", Status$reflection()], ["HighestBid", option_type(tuple_type(string_type, class_type("System.Numerics.BigInteger")))]]);
}

export function Rfq__GetSelectedBid(this$) {
    const matchValue = this$.Status;
    if (matchValue.tag === 0) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return toDecimal(matchValue.fields[0].SelectedBid[1]);
    }
}

export class RfqRaw extends Record {
    constructor(Creator, Asset, BidToken, MinBid, BidEnd, SelectionEnd, SelectionType, Status, HighestBid) {
        super();
        this.Creator = Creator;
        this.Asset = Asset;
        this.BidToken = BidToken;
        this.MinBid = MinBid;
        this.BidEnd = BidEnd;
        this.SelectionEnd = SelectionEnd;
        this.SelectionType = SelectionType;
        this.Status = Status;
        this.HighestBid = HighestBid;
    }
}

export function RfqRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveRfq.RfqRaw", [], RfqRaw, () => [["Creator", string_type], ["Asset", RfqGenericBalance$reflection()], ["BidToken", Denom$reflection()], ["MinBid", class_type("System.Numerics.BigInteger")], ["BidEnd", class_type("System.DateTimeOffset")], ["SelectionEnd", class_type("System.DateTimeOffset")], ["SelectionType", Selection$$reflection()], ["Status", Status$reflection()], ["HighestBid", option_type(tuple_type(string_type, class_type("System.Numerics.BigInteger")))]]);
}

export function RfqRaw__ToRfq_Z721C83C5(this$, address) {
    return new Rfq(address, this$.Creator, this$.Asset, this$.BidToken, this$.MinBid, this$.BidEnd, this$.SelectionEnd, this$.SelectionType, this$.Status, this$.HighestBid);
}

export const closedStatusDecoder = (path_2) => ((v) => object((get$) => {
    let arg10, objectArg;
    return new ClosedStatus((arg10 = tuple2(string, bigint), (objectArg = get$.Required, objectArg.Field("selected_bid", uncurry(2, arg10)))));
}, path_2, v));

export const rfqStatusDecoder = (path) => ((v) => object((get$) => {
    let objectArg;
    return new Status(1, (objectArg = get$.Required, objectArg.Field("closed", uncurry(2, closedStatusDecoder))));
}, path, v));

export const selectionTypeDecoder = (path_4) => ((v) => object((get$) => {
    let h;
    const objectArg = get$.Required;
    h = objectArg.Field("highest", (path_1, value_1) => option(string, path_1, value_1));
    let m;
    const objectArg_1 = get$.Required;
    m = objectArg_1.Field("manual", (path_3, value_3) => option(string, path_3, value_3));
    const matchValue = [h, m];
    let pattern_matching_result;
    if (matchValue[0] == null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[1] == null) {
        pattern_matching_result = 0;
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Selection$(0);
        }
        case 1: {
            return new Selection$(1);
        }
        case 2: {
            const arg20 = toString(m);
            const arg10_2 = toString(h);
            return toFail(printf("Error in deserializing rfq selection type. h: %s. m: %s"))(arg10_2)(arg20);
        }
    }
}, path_4, v));

export function openStatusDecoder(s) {
    if (s === "open") {
        return new Status(0);
    }
    else {
        throw (new Error("Unknown rfq status"));
    }
}

export const injectiveRfqRawDecoder = (path_11) => ((v) => object((get$) => {
    let objectArg, objectArg_1, objectArg_2, objectArg_3, objectArg_4, objectArg_5, x_2, objectArg_6, objectArg_7, arg10_8, decoder, objectArg_8;
    return new RfqRaw((objectArg = get$.Required, objectArg.Field("creator", string)), (objectArg_1 = get$.Required, objectArg_1.Field("asset", uncurry(2, rfqGenericBalanceDecoder))), (objectArg_2 = get$.Required, objectArg_2.Field("bid_token", uncurry(2, nativeDenomDecoder))), (objectArg_3 = get$.Required, objectArg_3.Field("min_bid", bigint)), DateOffset(toNumber(op_Division(toInt64((objectArg_4 = get$.Required, objectArg_4.Field("bid_end", bigint))), fromInteger(1000000000, false, 2))) * 1000, 0), DateOffset(toNumber(op_Division(toInt64((objectArg_5 = get$.Required, objectArg_5.Field("selection_end", bigint))), fromInteger(1000000000, false, 2))) * 1000, 0), (x_2 = ((objectArg_6 = get$.Required, objectArg_6.Field("selection_type", string))), (x_2 === "highest") ? (new Selection$(0)) : ((x_2 === "manual") ? (new Selection$(1)) : (() => {
        throw (new Error("Error in deserializing rfq selection type."));
    })())), (objectArg_7 = get$.Required, objectArg_7.Field("status", (path_7, value_7) => oneOf(ofArray([(path_6) => ((value_6) => map(openStatusDecoder, string, path_6, value_6)), rfqStatusDecoder]), path_7, value_7))), (arg10_8 = ((decoder = tuple2(string, bigint), (path_10) => ((value_10) => option(uncurry(2, decoder), path_10, value_10)))), (objectArg_8 = get$.Required, objectArg_8.Field("highest_bid", uncurry(2, arg10_8)))));
}, path_11, v));

export function deserializeRfq(address, data) {
    const raw = fromString(uncurry(2, injectiveRfqRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in rfq deserialization"));
    }
    else {
        return RfqRaw__ToRfq_Z721C83C5(raw.fields[0], address);
    }
}

export function deserializeRfqArray(jsonString) {
    const res = Result_Map(toArray, fromString((path, value) => keyValuePairs(uncurry(2, injectiveRfqRawDecoder), path, value), jsonString));
    if (res.tag === 1) {
        throw (new Error("failed in rfq array deserialization"));
    }
    else {
        return map_1((tupledArg) => RfqRaw__ToRfq_Z721C83C5(tupledArg[1], tupledArg[0]), res.fields[0]);
    }
}

