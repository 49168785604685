import { createElement } from "react";
import { createObj } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Util.js";
import { join } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/String.js";
import { empty, cons, ofArray, singleton, append } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/List.js";
import { Interop_reactApi } from "../../backoffice/client/src/fable_modules/Feliz.1.62.0/./Interop.fs.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { MuiHelpers_createElement } from "../../backoffice/client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormField } from "../CustomHooks/CustomHooks.fs.js";
import { value as value_17 } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Option.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../../backoffice/client/src/fable_modules/fable-library.3.7.9/Seq.js";
import { ifEnter } from "./UserInput.fs.js";

export function Content(contentInputProps) {
    let elems_1;
    const classes = contentInputProps.classes;
    const x = contentInputProps.x;
    return createElement("div", createObj(ofArray([["className", join(" ", append(singleton("section"), classes))], (elems_1 = [createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray([x]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function TitleText(titleTextInputProps) {
    const x = titleTextInputProps.x;
    return createElement("h4", {
        children: x,
    });
}

export function SubText(x) {
    return createElement("div", {
        className: "sub-text",
        children: x,
    });
}

export function Row(rowInputProps) {
    const children = rowInputProps.children;
    const classes = rowInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.row, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Col(colInputProps) {
    const children = colInputProps.children;
    const classes = colInputProps.classes;
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.col, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Grid(classes, children) {
    return createElement("div", {
        className: join(" ", cons(utils$002Emodule.grid, classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ErrorText(errorTextInputProps) {
    const classes = errorTextInputProps.classes;
    const text = errorTextInputProps.text;
    return createElement("div", {
        className: join(" ", cons("text-error", classes)),
        children: text,
    });
}

export function ButtonProgressLoader(buttonProgressLoaderInputProps) {
    return MuiHelpers_createElement(CircularProgress, [["className", join(" ", cons("progress-button", buttonProgressLoaderInputProps.classes))]]);
}

export function SectionTitle(sectionTitleInputProps) {
    const children = sectionTitleInputProps.children;
    const classes = sectionTitleInputProps.classes;
    return createElement(Row, {
        classes: cons("section-title", classes),
        children: children,
    });
}

export function Card(cardInputProps) {
    const children = cardInputProps.children;
    const classes = cardInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("card", classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function HDivider(hDividerInputProps) {
    const classes = hDividerInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("h-divider", classes)),
    });
}

export function VDivider(vDividerInputProps) {
    const classes = vDividerInputProps.classes;
    return createElement("div", {
        className: join(" ", cons("v-divider", classes)),
    });
}

export function SingleInputForm(singleInputFormInputProps) {
    const classes = singleInputFormInputProps.classes;
    const isLoading = singleInputFormInputProps.isLoading;
    const disabled = singleInputFormInputProps.disabled;
    const onSubmit = singleInputFormInputProps.onSubmit;
    const validator = singleInputFormInputProps.validator;
    const errorText = singleInputFormInputProps.errorText;
    const buttonText = singleInputFormInputProps.buttonText;
    const placeholder = singleInputFormInputProps.placeholder;
    const field = useFormField("", validator);
    const submitForm = (_arg1) => {
        const matchValue = field.Validate();
        if (matchValue == null) {
        }
        else {
            onSubmit(value_17(matchValue));
            field.Reset();
        }
    };
    return createElement(Col, {
        classes: empty(),
        children: toList(delay(() => {
            let elems_1, elems;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", cons("single-input-form", classes))], (elems_1 = [createElement("input", createObj(toList(delay(() => {
                let value_1;
                return append_1(field.Error ? singleton_1((value_1 = utils$002Emodule["input-error"], ["className", value_1])) : empty_1(), delay(() => append_1(singleton_1(["placeholder", placeholder]), delay(() => append_1(singleton_1(["value", field.FieldValue]), delay(() => append_1(singleton_1(["onChange", (ev) => {
                    field.SetFieldValue(ev.target.value);
                }]), delay(() => append_1(singleton_1(["onKeyDown", (ev_1) => {
                    ifEnter(submitForm, ev_1);
                }]), delay(() => singleton_1(["size", field.FieldValue.length])))))))))));
            })))), createElement("button", createObj(ofArray([["className", join(" ", [])], ["disabled", disabled ? true : isLoading], ["onClick", submitForm], (elems = toList(delay(() => append_1(singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray([buttonText]),
            })), delay(() => (isLoading ? singleton_1(createElement(ButtonProgressLoader, {
                classes: singleton("progress-white"),
            })) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => (field.Error ? singleton_1(createElement(ErrorText, {
                text: errorText,
                classes: singleton(utils$002Emodule["margin-s-top"]),
            })) : empty_1())));
        })),
    });
}

export function SearchTest(searchTestInputProps) {
    const classes = searchTestInputProps.classes;
    const isLoading = searchTestInputProps.isLoading;
    const disabled = searchTestInputProps.disabled;
    const onSubmit = searchTestInputProps.onSubmit;
    const validator = searchTestInputProps.validator;
    const errorText = searchTestInputProps.errorText;
    const buttonText = searchTestInputProps.buttonText;
    const placeholder = searchTestInputProps.placeholder;
    const field = useFormField("", validator);
    const submitForm = (_arg1) => {
        const matchValue = field.Validate();
        if (matchValue == null) {
        }
        else {
            onSubmit(value_17(matchValue));
        }
    };
    return createElement(Col, {
        classes: empty(),
        children: toList(delay(() => {
            let elems_1, elems;
            return append_1(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", cons("search-input", classes))], (elems_1 = [createElement("input", createObj(toList(delay(() => {
                let value_1;
                return append_1(field.Error ? singleton_1((value_1 = utils$002Emodule["input-error"], ["className", value_1])) : empty_1(), delay(() => append_1(singleton_1(["placeholder", placeholder]), delay(() => append_1(singleton_1(["value", field.FieldValue]), delay(() => append_1(singleton_1(["onChange", (ev) => {
                    field.SetFieldValue(ev.target.value);
                }]), delay(() => singleton_1(["onKeyDown", (ev_1) => {
                    ifEnter(submitForm, ev_1);
                }])))))))));
            })))), createElement("button", createObj(ofArray([["className", join(" ", [])], ["disabled", disabled ? true : isLoading], ["onClick", submitForm], (elems = toList(delay(() => append_1(singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray([buttonText]),
            })), delay(() => (isLoading ? singleton_1(createElement(ButtonProgressLoader, {
                classes: singleton("progress-white"),
            })) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => (field.Error ? singleton_1(createElement(ErrorText, {
                text: errorText,
                classes: singleton(utils$002Emodule["margin-s-top"]),
            })) : empty_1())));
        })),
    });
}

export function ModalClose(modalCloseInputProps) {
    let elems;
    const onClick = modalCloseInputProps.onClick;
    const closeImg = modalCloseInputProps.closeImg;
    return createElement("button", createObj(ofArray([["className", join(" ", ["modal-close"])], (elems = [createElement("img", {
        src: closeImg,
        alt: "Close",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg1) => {
        onClick();
    }]])));
}

export function Header(headerInputProps) {
    const classes = headerInputProps.classes;
    const text = headerInputProps.text;
    return createElement("div", {
        className: join(" ", append(singleton(utils$002Emodule["text-light"]), classes)),
        children: text,
    });
}

export function SortableHeader(sortableHeaderInputProps) {
    let elems;
    const classes = sortableHeaderInputProps.classes;
    const sort = sortableHeaderInputProps.sort;
    const img = sortableHeaderInputProps.img;
    const text = sortableHeaderInputProps.text;
    return createElement("div", createObj(ofArray([["className", join(" ", append(ofArray([utils$002Emodule["cursor-pointer"], utils$002Emodule["text-light"]]), classes))], (elems = [createElement("span", {
        children: [text],
    }), createElement("img", {
        className: join(" ", [utils$002Emodule["margin-s-left"]]),
        src: img,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg1) => {
        sort();
    }]])));
}

export function TextColumn(textColumnInputProps) {
    const classes = textColumnInputProps.classes;
    const text = textColumnInputProps.text;
    return createElement("div", {
        className: join(" ", append(empty(), classes)),
        children: text,
    });
}

export function Column(columnInputProps) {
    const classes = columnInputProps.classes;
    const children = columnInputProps.children;
    return createElement("div", {
        className: join(" ", append(empty(), classes)),
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

