import { tryParse, compare, pow, toNumber as toNumber_1, fromParts, equals, op_Division } from "./fable_modules/fable-library.3.7.9/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.7.9/Decimal.js";
import { toDecimal } from "./fable_modules/fable-library.3.7.9/BigInt.js";
import { InjectiveEnvironment, Paging$1, SortOrder, SolanaEnvironment, FPDECIMAL_ONE, FPDECIMAL_PERCENT_BASE } from "../../../lib/Domain/Types/Common.fs.js";
import { join, printf, toText } from "./fable_modules/fable-library.3.7.9/String.js";
import { createElement } from "react";
import currency$002Emodule from "./styles/currency.module.scss";
import { some, toArray, defaultArg } from "./fable_modules/fable-library.3.7.9/Option.js";
import { compare as compare_3, fastStructuralHash, comparePrimitives, stringHash, createObj } from "./fable_modules/fable-library.3.7.9/Util.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { DateParsing_parse, Interop_reactApi } from "./fable_modules/Feliz.1.62.0/./Interop.fs.js";
import { skip, sortByDescending, length, choose, sortBy, empty as empty_1, singleton as singleton_1, append as append_1, filter, map, exists, ofArray } from "./fable_modules/fable-library.3.7.9/List.js";
import { collect, toArray as toArray_1, take, length as length_1, skip as skip_1, iterate, map as map_1, empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.9/Seq.js";
import single_products$002Emodule from "./styles/single-products.module.scss";
import { Row, Col } from "../../../lib/CustomComponents/CustomComponents.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505, useReact_useEffectOnce_3A5B6456 } from "./fable_modules/Feliz.1.62.0/React.fs.js";
import pools$002Emodule from "./styles/pools.module.scss";
import { fetchInjectiveToken } from "../../../lib/Solana/Utils.fs.js";
import { InjectiveProduct__GetKind, InjectiveProduct__GetStrike, InjectiveProduct_GetProductName } from "../../../lib/Domain/Types/InjectiveProduct.fs.js";
import { addSeconds, utcNow, op_Subtraction } from "./fable_modules/fable-library.3.7.9/DateOffset.js";
import { compare as compare_2, toNumber } from "./fable_modules/fable-library.3.7.9/Long.js";
import { FSharpRef, toString } from "./fable_modules/fable-library.3.7.9/Types.js";
import { formatTime, formatDate } from "./DisplayTime.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { totalSeconds, fromSeconds } from "./fable_modules/fable-library.3.7.9/TimeSpan.js";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import { Kind } from "../../../lib/Domain/Types/FlexProduct.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { ofList } from "./fable_modules/fable-library.3.7.9/Map.js";
import { startImmediate } from "./fable_modules/fable-library.3.7.9/Async.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { getMainNetTokens } from "../../../lib/Solana/SolanaTokenRegistry.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { BackOfficeInjectiveHoldingProductOptionRfq__GetOptionExpiry, InjectiveHoldingBackendSortField } from "../../../lib/Domain/Types/InjectiveBackofficeSharedType.fs.js";
import { HeaderCell$1 } from "../../shared/Api.fs.js";
import { ApiHandler$1, useApi } from "./Server.fs.js";
import { List_distinct, List_distinctBy } from "./fable_modules/fable-library.3.7.9/Seq2.js";
import { toUniversalTime, fromDateTimeOffset, compare as compare_1 } from "./fable_modules/fable-library.3.7.9/Date.js";
import { EnvironmentFlag } from "./EnvironmentFlag.fs.js";
import { map as map_2 } from "./fable_modules/fable-library.3.7.9/Array.js";
import { rangeDouble } from "./fable_modules/fable-library.3.7.9/Range.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

export function formatStrikePriceSimple(strike) {
    if (strike.tag === 1) {
        return "";
    }
    else {
        const relativeStrike = op_Division(strike.fields[0], toDecimal(FPDECIMAL_PERCENT_BASE));
        return toText(printf("%M%%"))(relativeStrike);
    }
}

export function formatYield(y) {
    return toText(printf("%.2f%%"))(y);
}

export function CurrencyIcon(currencyIconInputProps) {
    const depositMint = currencyIconInputProps.depositMint;
    return createElement("img", {
        className: join(" ", [currency$002Emodule["currency-icon"]]),
        src: defaultArg(depositMint.logoURI, ""),
    });
}

export function Currency(currencyInputProps) {
    let elems;
    const depositMint = currencyInputProps.depositMint;
    const patternInput = [depositMint.symbol, depositMint.logoURI];
    return createElement("div", createObj(ofArray([["className", join(" ", [currency$002Emodule["currency-row"]])], (elems = [createElement(CurrencyIcon, {
        depositMint: depositMint,
    }), createElement("span", {
        className: join(" ", [utils$002Emodule["boldness-500"]]),
        children: `${patternInput[0]}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function CurrencyFilter(currencyFilterInputProps) {
    const setSelectedCurrency = currencyFilterInputProps.setSelectedCurrency;
    const selectedCurrency = currencyFilterInputProps.selectedCurrency;
    let currencyList;
    const selected = (token) => exists((selectedToken) => (selectedToken.symbol === token.symbol), selectedCurrency);
    currencyList = map((token_1) => {
        let elems;
        return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton(utils$002Emodule["cursor-pointer"]), delay(() => append(singleton(single_products$002Emodule["filter-deposit-currency"]), delay(() => ((selected(token_1) !== true) ? singleton(single_products$002Emodule.grayscale) : empty()))))))))], ["onClick", (_arg1) => {
            if (selected(token_1)) {
                setSelectedCurrency(filter((selectedToken_1) => (selectedToken_1.symbol !== token_1.symbol), selectedCurrency));
            }
            else {
                setSelectedCurrency(append_1(selectedCurrency, singleton_1(token_1)));
            }
        }], (elems = [createElement(Currency, {
            depositMint: token_1,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, currencyFilterInputProps.currencies);
    return createElement(Col, {
        classes: singleton_1(utils$002Emodule["gap-s"]),
        children: currencyList,
    });
}

export function MaturityFilter(maturityFilterInputProps) {
    const setSelectedMaturity = maturityFilterInputProps.setSelectedMaturity;
    const selectedMaturity = maturityFilterInputProps.selectedMaturity;
    const maturities = maturityFilterInputProps.maturities;
    useReact_useEffectOnce_3A5B6456(() => {
        setSelectedMaturity(maturities);
    });
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-m"]),
        children: toList(delay(() => map_1((days_2) => {
            let days, selected, elems;
            return createElement(Col, {
                classes: empty_1(),
                children: singleton_1((days = days_2, (selected = ((days_1) => exists((selectedDays) => equals(selectedDays, days_1), selectedMaturity)), createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule.col, utils$002Emodule.center, utils$002Emodule["gap-s"], utils$002Emodule["cursor-pointer"]])], ["onClick", (_arg2) => {
                    if (selected(days)) {
                        setSelectedMaturity(filter((selectedDays_1) => (!equals(selectedDays_1, days)), selectedMaturity));
                    }
                    else {
                        setSelectedMaturity(append_1(selectedMaturity, singleton_1(days)));
                    }
                }], (elems = [createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton(pools$002Emodule["duration-marker"]), delay(() => append(singleton(single_products$002Emodule["filter-maturity-marker"]), delay(() => (selected(days) ? singleton(pools$002Emodule["duration-marker-filled"]) : empty())))))))),
                }), createElement("div", {
                    className: join(" ", toList(delay(() => append(singleton(single_products$002Emodule["filter-maturity-marker-text"]), delay(() => (selected(days) ? singleton(single_products$002Emodule["filter-maturity-marker-text-filled"]) : empty())))))),
                    children: `${days} Days`,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))))),
            });
        }, maturities))),
    });
}

export function StrikePriceFilter(strikePriceFilterInputProps) {
    const setStrikingPriceHigh = strikePriceFilterInputProps.setStrikingPriceHigh;
    const strikingPriceHigh = strikePriceFilterInputProps.strikingPriceHigh;
    const setStrikingPriceLow = strikePriceFilterInputProps.setStrikingPriceLow;
    const strikingPriceLow = strikePriceFilterInputProps.strikingPriceLow;
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-s"]),
        children: ofArray([createElement("input", {
            className: join(" ", ["card", single_products$002Emodule["filter-striking-price"], utils$002Emodule.col]),
            type: "text",
            placeholder: "Min",
            value: strikingPriceLow,
            onChange: (ev) => {
                setStrikingPriceLow(ev.target.value);
            },
        }), createElement("span", {
            className: join(" ", [utils$002Emodule.col, utils$002Emodule.center]),
            children: "-",
        }), createElement("input", {
            className: join(" ", ["card", single_products$002Emodule["filter-striking-price"], utils$002Emodule.col]),
            type: "text",
            placeholder: "Max",
            value: strikingPriceHigh,
            onChange: (ev_1) => {
                setStrikingPriceHigh(ev_1.target.value);
            },
        })]),
    });
}

export function StartSettlementFilter(startSettlementFilterInputProps) {
    let elems;
    const setStartSettlementDate2 = startSettlementFilterInputProps.setStartSettlementDate2;
    const startSettlementDate2 = startSettlementFilterInputProps.startSettlementDate2;
    const setStartSettlementDate1 = startSettlementFilterInputProps.setStartSettlementDate1;
    const startSettlementDate1 = startSettlementFilterInputProps.startSettlementDate1;
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-m"]),
        children: singleton_1(createElement(Col, {
            classes: empty_1(),
            children: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule.col, utils$002Emodule.center, utils$002Emodule["gap-s"], utils$002Emodule["cursor-pointer"]])], (elems = [createElement("input", {
                type: "datetime-local",
                onChange: (ev) => {
                    iterate((newValue) => {
                        setStartSettlementDate1(newValue);
                    }, toArray(DateParsing_parse(ev.target.value)));
                },
                className: join(" ", []),
            }), createElement("input", {
                type: "datetime-local",
                onChange: (ev_1) => {
                    iterate((newValue_1) => {
                        setStartSettlementDate2(newValue_1);
                    }, toArray(DateParsing_parse(ev_1.target.value)));
                },
                className: join(" ", []),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
        })),
    });
}

export function EndDepositsFilter(endDepositsFilterInputProps) {
    let elems;
    const setEndDepositsDate2 = endDepositsFilterInputProps.setEndDepositsDate2;
    const endDepositsDate2 = endDepositsFilterInputProps.endDepositsDate2;
    const setEndDepositsDate1 = endDepositsFilterInputProps.setEndDepositsDate1;
    const endDepositsDate1 = endDepositsFilterInputProps.endDepositsDate1;
    return createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-m"]),
        children: singleton_1(createElement(Col, {
            classes: empty_1(),
            children: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule.col, utils$002Emodule.center, utils$002Emodule["gap-s"], utils$002Emodule["cursor-pointer"]])], (elems = [createElement("input", {
                type: "datetime-local",
                onChange: (ev) => {
                    iterate((newValue) => {
                        setEndDepositsDate1(newValue);
                    }, toArray(DateParsing_parse(ev.target.value)));
                },
                className: join(" ", []),
            }), createElement("input", {
                type: "datetime-local",
                onChange: (ev_1) => {
                    iterate((newValue_1) => {
                        setEndDepositsDate2(newValue_1);
                    }, toArray(DateParsing_parse(ev_1.target.value)));
                },
                className: join(" ", []),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
        })),
    });
}

export function Filters(filtersInputProps) {
    let elems;
    const setEndDepositsDate2 = filtersInputProps.setEndDepositsDate2;
    const selectedEndDepositsDate2 = filtersInputProps.selectedEndDepositsDate2;
    const setEndDepositsDate1 = filtersInputProps.setEndDepositsDate1;
    const selectedEndDepositsDate1 = filtersInputProps.selectedEndDepositsDate1;
    const setStartSettlementDate2 = filtersInputProps.setStartSettlementDate2;
    const selectedSettlementdate2 = filtersInputProps.selectedSettlementdate2;
    const setStartSettlementDate1 = filtersInputProps.setStartSettlementDate1;
    const selectedSettlementdate1 = filtersInputProps.selectedSettlementdate1;
    const setSelectedDepositCurrency = filtersInputProps.setSelectedDepositCurrency;
    const selectedDepositCurrency = filtersInputProps.selectedDepositCurrency;
    const setSelectedBaseCurrency = filtersInputProps.setSelectedBaseCurrency;
    const selectedBaseCurrency = filtersInputProps.selectedBaseCurrency;
    const setStrikingPriceHigh = filtersInputProps.setStrikingPriceHigh;
    const strikingPriceHigh = filtersInputProps.strikingPriceHigh;
    const setStrikingPriceLow = filtersInputProps.setStrikingPriceLow;
    const strikingPriceLow = filtersInputProps.strikingPriceLow;
    const setSelectedMaturity = filtersInputProps.setSelectedMaturity;
    const selectedMaturity = filtersInputProps.selectedMaturity;
    const filteredProductCount = filtersInputProps.filteredProductCount;
    const maturities = filtersInputProps.maturities;
    const depositCurrencies = filtersInputProps.depositCurrencies;
    const baseCurrencies = filtersInputProps.baseCurrencies;
    return createElement("div", createObj(ofArray([["className", join(" ", [single_products$002Emodule.filters])], (elems = [createElement(Row, {
        classes: singleton_1(utils$002Emodule["gap-l"]),
        children: ofArray([createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "Base Currency",
            }), createElement(CurrencyFilter, {
                currencies: baseCurrencies,
                selectedCurrency: selectedBaseCurrency,
                setSelectedCurrency: setSelectedBaseCurrency,
            })]),
        }), createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "Deposit Currency",
            }), createElement(CurrencyFilter, {
                currencies: depositCurrencies,
                selectedCurrency: selectedDepositCurrency,
                setSelectedCurrency: setSelectedDepositCurrency,
            })]),
        }), createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "Striking Price",
            }), createElement(StrikePriceFilter, {
                strikingPriceLow: strikingPriceLow,
                setStrikingPriceLow: setStrikingPriceLow,
                strikingPriceHigh: strikingPriceHigh,
                setStrikingPriceHigh: setStrikingPriceHigh,
            })]),
        }), createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "Maturity",
            }), createElement(MaturityFilter, {
                maturities: maturities,
                selectedMaturity: selectedMaturity,
                setSelectedMaturity: setSelectedMaturity,
            })]),
        }), createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "Start Settlement Date",
            }), createElement(StartSettlementFilter, {
                startSettlementDate1: selectedSettlementdate1,
                setStartSettlementDate1: setStartSettlementDate1,
                startSettlementDate2: selectedSettlementdate2,
                setStartSettlementDate2: setStartSettlementDate2,
            })]),
        }), createElement(Col, {
            classes: empty_1(),
            children: ofArray([createElement("div", {
                className: join(" ", [utils$002Emodule["margin-s-bottom"]]),
                children: "End Deposits Date",
            }), createElement(EndDepositsFilter, {
                endDepositsDate1: selectedEndDepositsDate1,
                setEndDepositsDate1: setEndDepositsDate1,
                endDepositsDate2: selectedEndDepositsDate2,
                setEndDepositsDate2: setEndDepositsDate2,
            })]),
        })]),
    }), createElement(Row, {
        classes: empty_1(),
        children: singleton_1(createElement(Col, {
            classes: singleton_1(utils$002Emodule["gap-l"]),
            children: singleton_1(createElement("div", {
                className: join(" ", [utils$002Emodule["text-light"]]),
                children: `${filteredProductCount} Products Found`,
            })),
        })),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ProductRowTable(productRowTableInputProps) {
    let matchValue_1, matchValue_2, copyOfStruct;
    const tokens = productRowTableInputProps.tokens;
    const product = productRowTableInputProps.product;
    const depositToken = fetchInjectiveToken(product.Holding.Balance.Denom, tokens);
    const baseToken = fetchInjectiveToken(product.Product.BaseDenom, tokens);
    const quoteToken = fetchInjectiveToken(product.Product.QuoteDenom, tokens);
    let baseCurrency;
    if (baseToken != null) {
        const token = baseToken;
        baseCurrency = token.symbol;
    }
    else {
        baseCurrency = "";
    }
    let quoteCurrency;
    if (quoteToken != null) {
        const token_1 = quoteToken;
        quoteCurrency = token_1.symbol;
    }
    else {
        quoteCurrency = "";
    }
    let productName;
    if (baseToken == null) {
        productName = "";
    }
    else {
        const token_2 = baseToken;
        productName = InjectiveProduct_GetProductName(token_2.symbol, product.Product);
    }
    const timeToEndCollection = op_Subtraction(product.Holding.EndCollection, utcNow());
    let optionExpiry;
    const matchValue = product.Option;
    optionExpiry = ((matchValue == null) ? addSeconds(product.Holding.EndCollection, toNumber(product.Product.Duration)) : matchValue.OptionExpiry);
    const maturity = toNumber(product.Product.Duration) / 86400;
    const estimatedYieldLow = formatYield(op_Division((matchValue_1 = product.Holding.LimitPrice, (matchValue_1.tag === 1) ? fromParts(1, 0, 0, true, 0) : ((matchValue_1.tag === 2) ? matchValue_1.fields[0].Price : matchValue_1.fields[0])), toDecimal(FPDECIMAL_PERCENT_BASE)));
    const estimatedYieldHigh = formatYield(op_Division((matchValue_2 = product.Holding.FairPrice, (matchValue_2.tag === 1) ? fromParts(1, 0, 0, true, 0) : ((matchValue_2.tag === 2) ? matchValue_2.fields[0].Price : matchValue_2.fields[0])), toDecimal(FPDECIMAL_PERCENT_BASE)));
    const strikePricePercent = formatStrikePriceSimple(InjectiveProduct__GetStrike(product.Product));
    let strikePrice;
    const matchValue_3 = product.Option;
    strikePrice = ((matchValue_3 == null) ? "" : ("$ " + ((copyOfStruct = toNumber_1(op_Division(matchValue_3.Strike, toDecimal(FPDECIMAL_ONE))), copyOfStruct.toString()))));
    let kind;
    const matchValue_4 = InjectiveProduct__GetKind(product.Product);
    kind = ((matchValue_4.tag === 1) ? "Down" : "Up");
    const getDecimalAmount = (amount) => {
        if (depositToken == null) {
            throw (new Error("Unkwown Deposit Currency"));
        }
        else {
            const token_3 = depositToken;
            return op_Division(toDecimal(amount), pow(fromParts(10, 0, 0, false, 0), token_3.decimals));
        }
    };
    const productStarted = product.Option != null;
    let patternInput;
    const matchValue_5 = product.Option;
    if (matchValue_5 == null) {
        patternInput = [false, false];
    }
    else {
        const op_2 = matchValue_5;
        patternInput = [equals(op_2.NbOptionRemaining, fromParts(0, 0, 0, false, 0)), op_2.CollateralCollected];
    }
    let patternInput_1;
    const matchValue_6 = product.Option;
    if (matchValue_6 == null) {
        patternInput_1 = [fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0)];
    }
    else {
        const op_3 = matchValue_6;
        patternInput_1 = [op_3.NbOption, op_3.NbOptionRemaining];
    }
    console.log(some(product.Holding));
    const delegationStatus = toString(product.Delegation[0]);
    let delegationTime;
    const matchValue_7 = product.Delegation[1];
    delegationTime = ((matchValue_7 == null) ? "NA" : formatDate(matchValue_7.DelegationTime));
    let undelegationTime;
    const matchValue_8 = product.Delegation[1];
    if (matchValue_8 == null) {
        undelegationTime = "NA";
    }
    else {
        const matchValue_9 = matchValue_8.UndelegationTime;
        undelegationTime = ((matchValue_9 == null) ? "NA" : formatDate(matchValue_9));
    }
    const children = ofArray([MuiHelpers_createElement(TableCell, [["children", productName]]), MuiHelpers_createElement(TableCell, [["children", kind]]), MuiHelpers_createElement(TableCell, [["children", `${product.Holding.Id}`]]), MuiHelpers_createElement(TableCell, [["children", `${estimatedYieldLow} - ${estimatedYieldHigh}`]]), MuiHelpers_createElement(TableCell, [["children", strikePricePercent]]), MuiHelpers_createElement(TableCell, [["children", strikePrice]]), MuiHelpers_createElement(TableCell, [["children", `${maturity} Days`]]), MuiHelpers_createElement(TableCell, [["children", `${formatDate(product.Holding.EndCollection)}`]]), MuiHelpers_createElement(TableCell, toList(delay(() => {
        const time = formatTime(fromSeconds(totalSeconds(timeToEndCollection)));
        return singleton(["children", `${time}`]);
    }))), MuiHelpers_createElement(TableCell, [["children", `${formatDate(optionExpiry)}`]]), MuiHelpers_createElement(TableCell, [["children", `${baseCurrency}`]]), MuiHelpers_createElement(TableCell, [["children", `${quoteCurrency}`]]), MuiHelpers_createElement(TableCell, [["children", `${getDecimalAmount(product.Holding.TotalDepositedAmount)}`]]), MuiHelpers_createElement(TableCell, [["children", `${getDecimalAmount(product.Holding.Balance.Amount)}`]]), MuiHelpers_createElement(TableCell, [["children", `${productStarted}`]]), MuiHelpers_createElement(TableCell, [["children", `${patternInput_1[0]}`]]), MuiHelpers_createElement(TableCell, [["children", `${patternInput_1[1]}`]]), MuiHelpers_createElement(TableCell, [["children", `${patternInput[0]}`]]), MuiHelpers_createElement(TableCell, [["children", `${patternInput[1]}`]]), MuiHelpers_createElement(TableCell, [["children", `${delegationStatus}`]]), MuiHelpers_createElement(TableCell, [["children", `${delegationTime}`]]), MuiHelpers_createElement(TableCell, [["children", `${undelegationTime}`]])]);
    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function PagingRow(pagingRowInputProps) {
    let elems;
    const changePageCallback = pagingRowInputProps.changePageCallback;
    const totalPageCount = pagingRowInputProps.totalPageCount;
    const paging = pagingRowInputProps.paging;
    return createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule["padding-m"], utils$002Emodule["margin-s-bottom"]])], (elems = [MuiHelpers_createElement(Pagination, [["count", (totalPageCount != null) ? totalPageCount : 1], ["page", paging.PageNumber], ["showFirstButton", true], ["showLastButton", true], ["onChange", (delegateArg0, delegateArg1) => {
        changePageCallback(delegateArg1);
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function InjectiveProducts() {
    let matchValue_10, matchValue_4, elems_5, elems_4, children_1, elems_1, elems_3;
    const patternInput = useFeliz_React__React_useState_Static_1505(new SolanaEnvironment(1));
    const environment = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new SortOrder(1));
    const sortOrder = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(100);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(empty_1());
    const selectedMaturity = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505("");
    const strikingPriceLow = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505("");
    const strikingPriceHigh = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(new Kind(1));
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(empty_1());
    const selectedBaseCurrency = patternInput_10[0];
    const patternInput_11 = useFeliz_React__React_useState_Static_1505(empty_1());
    const selectedDepositCurrency = patternInput_11[0];
    const patternInput_12 = useFeliz_React__React_useState_Static_1505(void 0);
    const startSettlementDate1 = patternInput_12[0];
    const patternInput_13 = useFeliz_React__React_useState_Static_1505(void 0);
    const startSettlementDate2 = patternInput_13[0];
    const patternInput_14 = useFeliz_React__React_useState_Static_1505(void 0);
    const endDepositsDate1 = patternInput_14[0];
    const patternInput_15 = useFeliz_React__React_useState_Static_1505(void 0);
    const endDepositsDate2 = patternInput_15[0];
    const patternInput_16 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_17 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_17[0];
    const patternInput_18 = useFeliz_React__React_useState_Static_1505(ofList(empty_1()));
    const tokens = patternInput_18[0];
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(getMainNetTokens(), (_arg1) => {
            patternInput_18[1](_arg1);
            return singleton_2.Zero();
        })));
    });
    const patternInput_19 = useFeliz_React__React_useState_Static_1505(1);
    const paging = new Paging$1(patternInput_4[0], patternInput_19[0], patternInput_5[0], patternInput_2[0], sortOrder);
    const auth = useAuth0();
    const headerCells = ofArray([new HeaderCell$1("Product Name", new InjectiveHoldingBackendSortField(0)), new HeaderCell$1("Kind", new InjectiveHoldingBackendSortField(1)), new HeaderCell$1("Holding Id", new InjectiveHoldingBackendSortField(2)), new HeaderCell$1("limit - Fair Price", new InjectiveHoldingBackendSortField(3)), new HeaderCell$1("Strike Price (%)", new InjectiveHoldingBackendSortField(4)), new HeaderCell$1("Strike Price", new InjectiveHoldingBackendSortField(5)), new HeaderCell$1("Duration", new InjectiveHoldingBackendSortField(6)), new HeaderCell$1("End Collection", new InjectiveHoldingBackendSortField(7)), new HeaderCell$1("End Collection Clock", new InjectiveHoldingBackendSortField(8)), new HeaderCell$1("Option Expiry", new InjectiveHoldingBackendSortField(9)), new HeaderCell$1("Base Currency", new InjectiveHoldingBackendSortField(10)), new HeaderCell$1("Quote Currency", new InjectiveHoldingBackendSortField(11)), new HeaderCell$1("Total Deposited Amount", new InjectiveHoldingBackendSortField(12)), new HeaderCell$1("Amount Locked", new InjectiveHoldingBackendSortField(13)), new HeaderCell$1("Product Started", new InjectiveHoldingBackendSortField(14)), new HeaderCell$1("Nb Option", new InjectiveHoldingBackendSortField(15)), new HeaderCell$1("Nb Option Remaining", new InjectiveHoldingBackendSortField(16)), new HeaderCell$1("Options Remaining", new InjectiveHoldingBackendSortField(17)), new HeaderCell$1("Collateral Collected", new InjectiveHoldingBackendSortField(18)), new HeaderCell$1("Delegation Status", new InjectiveHoldingBackendSortField(19)), new HeaderCell$1("Delegation Time", new InjectiveHoldingBackendSortField(20)), new HeaderCell$1("Undelegation Time", new InjectiveHoldingBackendSortField(21))]);
    const injectiveEnv = (environment.tag === 0) ? (new InjectiveEnvironment(0)) : (new InjectiveEnvironment(1));
    useReact_useEffect_Z101E1A95(useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => useApi(new ApiHandler$1(() => auth.getIdTokenClaims(), (server) => singleton_2.Delay(() => singleton_2.Bind(server.GetInjectiveProducts(injectiveEnv, paging), (_arg4) => singleton_2.Return(_arg4))))), patternInput_17[1]), [environment]);
    switch (data.tag) {
        case 1: {
            return createElement("h1", {
                children: ["Loading"],
            });
        }
        case 3: {
            const value_1 = data.fields[0].message;
            return createElement("p", {
                children: [value_1],
            });
        }
        case 2: {
            const productData = data.fields[0];
            const baseCurrencies = sortBy((token_2) => token_2.symbol, List_distinctBy((token_1) => token_1.symbol, choose((product) => fetchInjectiveToken(product.Product.BaseDenom, tokens), productData.Products), {
                Equals: (x_1, y) => (x_1 === y),
                GetHashCode: stringHash,
            }), {
                Compare: comparePrimitives,
            });
            const depositCurrencies = sortBy((token_5) => token_5.symbol, List_distinctBy((token_4) => token_4.symbol, choose((product_1) => fetchInjectiveToken(product_1.Holding.Balance.Denom, tokens), productData.Products), {
                Equals: (x_3, y_2) => (x_3 === y_2),
                GetHashCode: stringHash,
            }), {
                Compare: comparePrimitives,
            });
            const maturities = sortBy((maturity) => maturity, List_distinct(map((product_2) => op_Division(new Decimal(toNumber(product_2.Product.Duration)), fromParts(86400, 0, 0, false, 0)), productData.Products), {
                Equals: equals,
                GetHashCode: fastStructuralHash,
            }), {
                Compare: compare,
            });
            const filteredProductsList = filter((product_9) => {
                let date2_2, date1_2;
                const matchValue_3 = [endDepositsDate1, endDepositsDate2];
                let pattern_matching_result;
                if (matchValue_3[0] != null) {
                    if (matchValue_3[1] != null) {
                        if ((date2_2 = matchValue_3[1], (date1_2 = matchValue_3[0], (compare_1(fromDateTimeOffset(product_9.Holding.EndCollection, 0), toUniversalTime(date1_2)) > 0) && (compare_1(fromDateTimeOffset(product_9.Holding.EndCollection, 0), toUniversalTime(date2_2)) <= 0)))) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        let pattern_matching_result_1;
                        if (matchValue_3[0] == null) {
                            if (matchValue_3[1] == null) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                return true;
                            }
                            case 1: {
                                return false;
                            }
                        }
                    }
                }
            }, filter((product_8) => {
                let date2;
                let expiry;
                let copyOfStruct = BackOfficeInjectiveHoldingProductOptionRfq__GetOptionExpiry(product_8);
                expiry = fromDateTimeOffset(copyOfStruct, 0);
                const matchValue_2 = [startSettlementDate1, startSettlementDate2];
                let pattern_matching_result_2;
                if (matchValue_2[0] != null) {
                    if (matchValue_2[1] != null) {
                        if ((date2 = matchValue_2[1], (compare_1(expiry, toUniversalTime(matchValue_2[0])) > 0) && (compare_1(expiry, toUniversalTime(date2)) <= 0))) {
                            pattern_matching_result_2 = 0;
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                }
                else {
                    pattern_matching_result_2 = 1;
                }
                switch (pattern_matching_result_2) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        let pattern_matching_result_3;
                        if (matchValue_2[0] == null) {
                            if (matchValue_2[1] == null) {
                                pattern_matching_result_3 = 0;
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                        switch (pattern_matching_result_3) {
                            case 0: {
                                return true;
                            }
                            case 1: {
                                return false;
                            }
                        }
                    }
                }
            }, filter((product_7) => {
                const baseToken = fetchInjectiveToken(product_7.Product.BaseDenom, tokens);
                if (baseToken != null) {
                    const filterToken = baseToken;
                    if (length(selectedBaseCurrency) === 0) {
                        return true;
                    }
                    else {
                        return exists((token_6) => (token_6.symbol === filterToken.symbol), selectedBaseCurrency);
                    }
                }
                else {
                    return false;
                }
            }, filter((product_6) => {
                const depositToken = fetchInjectiveToken(product_6.Holding.Balance.Denom, tokens);
                if (depositToken != null) {
                    const filterToken_1 = depositToken;
                    if (length(selectedDepositCurrency) === 0) {
                        return true;
                    }
                    else {
                        return exists((token_7) => (token_7.symbol === filterToken_1.symbol), selectedDepositCurrency);
                    }
                }
                else {
                    return false;
                }
            }, filter((product_5) => {
                const filterMaturity = op_Division(new Decimal(toNumber(product_5.Product.Duration)), fromParts(86400, 0, 0, false, 0));
                return exists((maturity_1) => equals(filterMaturity, maturity_1), selectedMaturity);
            }, filter((product_4) => {
                let outArg, outArg_1;
                const matchValue = product_4.Option;
                if (matchValue == null) {
                    return true;
                }
                else {
                    const k = op_Division(matchValue.Strike, toDecimal(FPDECIMAL_ONE));
                    const matchValue_1 = [(outArg = (new Decimal(0)), [tryParse(strikingPriceLow, new FSharpRef(() => outArg, (v) => {
                        outArg = v;
                    })), outArg]), (outArg_1 = (new Decimal(0)), [tryParse(strikingPriceHigh, new FSharpRef(() => outArg_1, (v_1) => {
                        outArg_1 = v_1;
                    })), outArg_1])];
                    if (matchValue_1[0][0]) {
                        if (matchValue_1[1][0]) {
                            if (compare(k, matchValue_1[0][1]) >= 0) {
                                return compare(k, matchValue_1[1][1]) <= 0;
                            }
                            else {
                                return false;
                            }
                        }
                        else {
                            return compare(k, matchValue_1[0][1]) >= 0;
                        }
                    }
                    else if (matchValue_1[1][0]) {
                        return compare(k, matchValue_1[1][1]) <= 0;
                    }
                    else {
                        return true;
                    }
                }
            }, productData.Products))))));
            const filteredProductCount = length(filteredProductsList) | 0;
            const filteredProducts2 = ((sortOrder.tag === 1) ? ((matchValue_10 = paging.SortField, (matchValue_10 != null) ? ((matchValue_10.tag === 3) ? ((list_33) => sortByDescending((p_13) => {
                const matchValue_11 = p_13.Holding.FairPrice;
                switch (matchValue_11.tag) {
                    case 1: {
                        return fromParts(1, 0, 0, true, 0);
                    }
                    case 2: {
                        return matchValue_11.fields[0].Price;
                    }
                    default: {
                        return matchValue_11.fields[0];
                    }
                }
            }, list_33, {
                Compare: compare,
            })) : ((matchValue_10.tag === 6) ? ((list_34) => sortByDescending((p_16) => p_16.Product.Duration, list_34, {
                Compare: compare_2,
            })) : ((matchValue_10.tag === 5) ? ((list_35) => sortByDescending((p_17) => {
                const matchValue_12 = p_17.Option;
                if (matchValue_12 == null) {
                    return fromParts(1, 0, 0, true, 0);
                }
                else {
                    return op_Division(matchValue_12.Strike, toDecimal(FPDECIMAL_ONE));
                }
            }, list_35, {
                Compare: compare,
            })) : ((matchValue_10.tag === 9) ? ((list_36) => sortByDescending(BackOfficeInjectiveHoldingProductOptionRfq__GetOptionExpiry, list_36, {
                Compare: compare_1,
            })) : ((matchValue_10.tag === 7) ? ((list_37) => sortByDescending((p_19) => p_19.Holding.EndCollection, list_37, {
                Compare: compare_1,
            })) : ((matchValue_10.tag === 1) ? ((list_38) => sortByDescending((p_20) => InjectiveProduct__GetKind(p_20.Product), list_38, {
                Compare: compare_3,
            })) : ((matchValue_10.tag === 0) ? ((list_39) => sortByDescending((p_21) => {
                const spotToken_1 = fetchInjectiveToken(p_21.Product.BaseDenom, tokens);
                if (spotToken_1 == null) {
                    return "";
                }
                else {
                    const t_3 = spotToken_1;
                    return InjectiveProduct_GetProductName(t_3.symbol, p_21.Product);
                }
            }, list_39, {
                Compare: comparePrimitives,
            })) : ((matchValue_10.tag === 2) ? ((list_40) => sortByDescending((p_22) => p_22.Holding.Id, list_40, {
                Compare: comparePrimitives,
            })) : ((matchValue_10.tag === 10) ? ((list_41) => sortByDescending((p_23) => {
                const baseToken_3 = fetchInjectiveToken(p_23.Product.BaseDenom, tokens);
                if (baseToken_3 == null) {
                    return -1;
                }
                else {
                    const t_4 = baseToken_3;
                    const matchValue_13 = t_4.symbol.toLocaleLowerCase();
                    switch (matchValue_13) {
                        case "inj": {
                            return 0;
                        }
                        case "weth": {
                            return 1;
                        }
                        case "atom": {
                            return 2;
                        }
                        default: {
                            return 3;
                        }
                    }
                }
            }, list_41, {
                Compare: comparePrimitives,
            })) : ((matchValue_10.tag === 11) ? ((list_42) => sortByDescending((p_24) => {
                const baseToken_4 = fetchInjectiveToken(p_24.Product.BaseDenom, tokens);
                if (baseToken_4 == null) {
                    return -1;
                }
                else {
                    const t_5 = baseToken_4;
                    const matchValue_14 = t_5.symbol.toLocaleLowerCase();
                    switch (matchValue_14) {
                        case "inj": {
                            return 0;
                        }
                        case "weth": {
                            return 1;
                        }
                        case "atom": {
                            return 2;
                        }
                        default: {
                            return 3;
                        }
                    }
                }
            }, list_42, {
                Compare: comparePrimitives,
            })) : ((matchValue_10.tag === 19) ? ((list_43) => sortByDescending((p_25) => {
                const matchValue_15 = p_25.Delegation[0];
                switch (matchValue_15.tag) {
                    case 1: {
                        return 0;
                    }
                    case 2: {
                        return 1;
                    }
                    case 3: {
                        return 2;
                    }
                    case 4: {
                        return 3;
                    }
                    default: {
                        return 10;
                    }
                }
            }, list_43, {
                Compare: comparePrimitives,
            })) : ((list_44) => skip(0, list_44))))))))))))) : ((list_44) => skip(0, list_44)))) : ((matchValue_4 = paging.SortField, (matchValue_4 != null) ? ((matchValue_4.tag === 3) ? ((list_21) => sortBy((p) => {
                const matchValue_5 = p.Holding.FairPrice;
                switch (matchValue_5.tag) {
                    case 1: {
                        return fromParts(1, 0, 0, true, 0);
                    }
                    case 2: {
                        return matchValue_5.fields[0].Price;
                    }
                    default: {
                        return matchValue_5.fields[0];
                    }
                }
            }, list_21, {
                Compare: compare,
            })) : ((matchValue_4.tag === 6) ? ((list_22) => sortBy((p_3) => p_3.Product.Duration, list_22, {
                Compare: compare_2,
            })) : ((matchValue_4.tag === 5) ? ((list_23) => sortBy((p_4) => {
                const matchValue_6 = p_4.Option;
                if (matchValue_6 == null) {
                    return fromParts(1, 0, 0, true, 0);
                }
                else {
                    return op_Division(matchValue_6.Strike, toDecimal(FPDECIMAL_ONE));
                }
            }, list_23, {
                Compare: compare,
            })) : ((matchValue_4.tag === 9) ? ((list_24) => sortBy(BackOfficeInjectiveHoldingProductOptionRfq__GetOptionExpiry, list_24, {
                Compare: compare_1,
            })) : ((matchValue_4.tag === 7) ? ((list_25) => sortBy((p_6) => p_6.Holding.EndCollection, list_25, {
                Compare: compare_1,
            })) : ((matchValue_4.tag === 1) ? ((list_26) => sortBy((p_7) => InjectiveProduct__GetKind(p_7.Product), list_26, {
                Compare: compare_3,
            })) : ((matchValue_4.tag === 0) ? ((list_27) => sortBy((p_8) => {
                const spotToken = fetchInjectiveToken(p_8.Product.BaseDenom, tokens);
                if (spotToken == null) {
                    return "";
                }
                else {
                    const t = spotToken;
                    return InjectiveProduct_GetProductName(t.symbol, p_8.Product);
                }
            }, list_27, {
                Compare: comparePrimitives,
            })) : ((matchValue_4.tag === 2) ? ((list_28) => sortBy((p_9) => p_9.Holding.Id, list_28, {
                Compare: comparePrimitives,
            })) : ((matchValue_4.tag === 10) ? ((list_29) => sortBy((p_10) => {
                const baseToken_1 = fetchInjectiveToken(p_10.Product.BaseDenom, tokens);
                if (baseToken_1 == null) {
                    return -1;
                }
                else {
                    const t_1 = baseToken_1;
                    const matchValue_7 = t_1.symbol.toLocaleLowerCase();
                    switch (matchValue_7) {
                        case "inj": {
                            return 0;
                        }
                        case "weth": {
                            return 1;
                        }
                        case "atom": {
                            return 2;
                        }
                        default: {
                            return 3;
                        }
                    }
                }
            }, list_29, {
                Compare: comparePrimitives,
            })) : ((matchValue_4.tag === 11) ? ((list_30) => sortBy((p_11) => {
                const baseToken_2 = fetchInjectiveToken(p_11.Product.BaseDenom, tokens);
                if (baseToken_2 == null) {
                    return -1;
                }
                else {
                    const t_2 = baseToken_2;
                    const matchValue_8 = t_2.symbol.toLocaleLowerCase();
                    switch (matchValue_8) {
                        case "inj": {
                            return 0;
                        }
                        case "weth": {
                            return 1;
                        }
                        case "atom": {
                            return 2;
                        }
                        default: {
                            return 3;
                        }
                    }
                }
            }, list_30, {
                Compare: comparePrimitives,
            })) : ((matchValue_4.tag === 19) ? ((list_31) => sortBy((p_12) => {
                const matchValue_9 = p_12.Delegation[0];
                switch (matchValue_9.tag) {
                    case 1: {
                        return 0;
                    }
                    case 2: {
                        return 1;
                    }
                    case 3: {
                        return 2;
                    }
                    case 4: {
                        return 3;
                    }
                    default: {
                        return 10;
                    }
                }
            }, list_31, {
                Compare: comparePrimitives,
            })) : ((list_32) => skip(0, list_32))))))))))))) : ((list_32) => skip(0, list_32)))))(filteredProductsList);
            let injectiveProductsList;
            const skippedProducts = skip_1(paging.PageSize * (paging.PageNumber - 1), filteredProducts2);
            injectiveProductsList = ((length_1(skippedProducts) > paging.PageSize) ? toList(take(paging.PageSize, skippedProducts)) : toList(skippedProducts));
            const pageSize_1 = ((paging.PageSize <= 0) ? 10 : paging.PageSize) | 0;
            const totalPageCount = (~(~(((length(filteredProducts2) + paging.PageSize) - 1) / pageSize_1))) | 0;
            return createElement(Row, {
                classes: singleton_1(utils$002Emodule["gap-l"]),
                children: ofArray([createElement(EnvironmentFlag, {
                    environment: environment,
                    setEnvironment: patternInput[1],
                }), createElement("button", {
                    className: join(" ", []),
                    children: "Export CSV",
                    onClick: (_arg1_1) => {
                        const rows = document.querySelectorAll("table tr");
                        const data_2 = join("\n", map_2((x) => join(",", x), toArray_1(delay(() => collect((i) => {
                            const cols = (rows[i]).querySelectorAll("td, th");
                            return singleton(toArray_1(delay(() => collect((j) => {
                                const node = cols[j];
                                return singleton(node.innerText);
                            }, rangeDouble(0, 1, cols.length - 1)))));
                        }, rangeDouble(0, 1, rows.length - 1))))));
                        const fileType = new (class {
                            get type() {
                                return "text/csv";
                            }
                            set type(_arg2) {
                            }
                            get endings() {
                                return "transparent";
                            }
                            set endings(_arg3) {
                            }
                        }
                        )();
                        const csvFile = new Blob([data_2], fileType);
                        const a = document.createElement("a");
                        const url = URL.createObjectURL(csvFile);
                        a.setAttribute("href", url);
                        a.setAttribute("download", "products.csv");
                        a.click();
                    },
                }), createElement(Col, {
                    classes: empty_1(),
                    children: ofArray([createElement(Row, {
                        classes: singleton_1(utils$002Emodule["gap-l"]),
                        children: singleton_1(createElement(Col, {
                            classes: empty_1(),
                            children: singleton_1(createElement(Filters, {
                                baseCurrencies: baseCurrencies,
                                depositCurrencies: depositCurrencies,
                                maturities: maturities,
                                filteredProductCount: filteredProductCount,
                                selectedMaturity: selectedMaturity,
                                setSelectedMaturity: patternInput_6[1],
                                strikingPriceLow: strikingPriceLow,
                                setStrikingPriceLow: patternInput_7[1],
                                strikingPriceHigh: strikingPriceHigh,
                                setStrikingPriceHigh: patternInput_8[1],
                                selectedBaseCurrency: selectedBaseCurrency,
                                setSelectedBaseCurrency: patternInput_10[1],
                                selectedDepositCurrency: selectedDepositCurrency,
                                setSelectedDepositCurrency: patternInput_11[1],
                                selectedSettlementdate1: startSettlementDate1,
                                setStartSettlementDate1: patternInput_12[1],
                                selectedSettlementdate2: startSettlementDate2,
                                setStartSettlementDate2: patternInput_13[1],
                                selectedEndDepositsDate1: endDepositsDate1,
                                setEndDepositsDate1: patternInput_14[1],
                                selectedEndDepositsDate2: endDepositsDate2,
                                setEndDepositsDate2: patternInput_15[1],
                            })),
                        })),
                    }), createElement(Row, {
                        classes: singleton_1(utils$002Emodule["gap-l"]),
                        children: singleton_1(createElement(Col, {
                            classes: empty_1(),
                            children: singleton_1(MuiHelpers_createElement(TableContainer, [["className", join(" ", [])], (elems_5 = [MuiHelpers_createElement(Table, [["id", "injective-product-table"], ["className", join(" ", [])], (elems_4 = [(children_1 = singleton_1(MuiHelpers_createElement(TableRow, [["className", join(" ", [])], (elems_1 = map((headerCol) => {
                                const children = singleton_1(MuiHelpers_createElement(TableSortLabel, [["children", headerCol.Label], ["onClick", (_arg2_1) => {
                                    patternInput_2[1](headerCol.Key);
                                    patternInput_1[1]((sortOrder.tag === 1) ? (new SortOrder(0)) : (new SortOrder(1)));
                                }]]));
                                return MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
                            }, headerCells), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), MuiHelpers_createElement(TableBody, [(elems_3 = map((product_10) => createElement(ProductRowTable, {
                                product: product_10,
                                tokens: tokens,
                            }), injectiveProductsList), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])),
                        })),
                    }), createElement(Row, {
                        classes: empty_1(),
                        children: singleton_1(createElement(Col, {
                            classes: empty_1(),
                            children: singleton_1(createElement(PagingRow, {
                                paging: paging,
                                totalPageCount: totalPageCount,
                                changePageCallback: (p_26) => {
                                    patternInput_19[1](p_26);
                                },
                            })),
                        })),
                    })]),
                })]),
            });
        }
        default: {
            return null;
        }
    }
}

