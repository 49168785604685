import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.62.0/React.fs.js";
import { toString } from "./fable_modules/fable-library.3.7.9/Types.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.9/Util.js";
import { ofArray, singleton } from "./fable_modules/fable-library.3.7.9/List.js";
import { join } from "./fable_modules/fable-library.3.7.9/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import { SolanaEnvironment } from "../../../lib/Domain/Types/Common.fs.js";
import { some } from "./fable_modules/fable-library.3.7.9/Option.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.62.0/./Interop.fs.js";

export function EnvironmentFlag(environmentFlagInputProps) {
    let properties, elems;
    const setEnvironment = environmentFlagInputProps.setEnvironment;
    const patternInput = useFeliz_React__React_useState_Static_1505(toString(environmentFlagInputProps.environment));
    return createElement("select", createObj(ofArray([(properties = singleton(["height", 40 + "px"]), ["style", createObj(properties)]), ["className", join(" ", [utils$002Emodule.center, utils$002Emodule["margin-m-top"]])], ["value", patternInput[0]], ["onChange", (ev) => {
        const e = ev.target.value;
        patternInput[1](e);
        switch (e) {
            case "Mainnet": {
                setEnvironment(new SolanaEnvironment(1));
                break;
            }
            case "Devnet": {
                setEnvironment(new SolanaEnvironment(0));
                break;
            }
            default: {
                console.log(some("Unknown environment"));
            }
        }
    }], (elems = [createElement("option", {
        children: "Devnet",
        value: "Devnet",
    }), createElement("option", {
        children: "Mainnet",
        value: "Mainnet",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

