
export function createToken(address, chailId, decimals, logoURI, name, symbol) {
    return new (class {
        get symbol() {
            return symbol;
        }
        set symbol(_arg1) {
        }
        get address() {
            return address;
        }
        get name() {
            return name;
        }
        get decimals() {
            return decimals;
        }
        get chailId() {
            return chailId;
        }
        get logoURI() {
            return logoURI;
        }
    }
    )();
}

