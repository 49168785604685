import { printf, toText, split, isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.7.9/String.js";
import { ofArray, collect, filter } from "./fable_modules/fable-library.3.7.9/List.js";
import { IServerApi$reflection, routerPaths } from "../../shared/Api.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.19.0/Remoting.fs.js";
import { Record } from "./fable_modules/fable-library.3.7.9/Types.js";
import { record_type, lambda_type, class_type, unit_type } from "./fable_modules/fable-library.3.7.9/Reflection.js";
import { singleton } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { awaitPromise } from "./fable_modules/fable-library.3.7.9/Async.js";
import js_cookie from "js-cookie";

export const virtualPath = eval("window.location.pathname");

export function combine(paths) {
    const arg10 = join("/", filter((arg) => (!isNullOrWhiteSpace(arg)), filter((segment) => (!(segment.indexOf(".") >= 0)), collect((path) => ofArray(split(path, ["/"], null, 0)), paths))));
    return toText(printf("/%s"))(arg10);
}

export function normalize(path) {
    return combine(ofArray([virtualPath, path]));
}

export function normalizeRoutes(typeName, methodName) {
    return normalize(routerPaths(typeName, methodName));
}

export const Api = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), {
    ResolveType: IServerApi$reflection,
});

export class ApiHandler$1 extends Record {
    constructor(userClaims, call) {
        super();
        this.userClaims = userClaims;
        this.call = call;
    }
}

export function ApiHandler$1$reflection(gen0) {
    return record_type("Server.ApiHandler`1", [gen0], ApiHandler$1, () => [["userClaims", lambda_type(unit_type, class_type("Fable.Core.JS.Promise`1", [class_type("Authentication.AuthZeroTokenClaims")]))], ["call", lambda_type(IServerApi$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [gen0]))]]);
}

export function useApi(input) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(input.userClaims()), (_arg1) => {
        let options_1, arg10_1;
        const userClaims = _arg1;
        let client;
        let cookieRes;
        const arg10 = userClaims.__raw;
        const objectArg = js_cookie;
        cookieRes = objectArg.set("access_token", arg10);
        client = Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), RemotingModule_withAuthorizationHeader((arg10_1 = (userClaims.__raw), toText(printf("Bearer %s"))(arg10_1)), options_1)), {
            ResolveType: IServerApi$reflection,
        });
        return singleton.ReturnFrom(input.call(client));
    }));
}

