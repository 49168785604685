import { printf, toText } from "../client/src/fable_modules/fable-library.3.7.9/String.js";
import { Union, Record } from "../client/src/fable_modules/fable-library.3.7.9/Types.js";
import { option_type, list_type, lambda_type, class_type, unit_type, union_type, string_type, record_type, int32_type } from "../client/src/fable_modules/fable-library.3.7.9/Reflection.js";
import { InjectiveEnvironment$reflection, Paging$1$reflection, SortOrder$reflection, SolanaEnvironment$reflection } from "../../lib/Domain/Types/Common.fs.js";
import { FlexProductsPage$reflection, FlexProductBackendSortField$reflection, Kind$reflection } from "../../lib/Domain/Types/FlexProduct.fs.js";
import { InjectiveProductsPage$reflection, InjectiveHoldingBackendSortField$reflection } from "../../lib/Domain/Types/InjectiveBackofficeSharedType.fs.js";
import { ClientStatPage$reflection, ClientStatsSortField$reflection } from "../../lib/Domain/Types/ClientStats.fs.js";
import { Settings$reflection } from "../../lib/Domain/Types/Settings.fs.js";

export function routerPaths(typeName, method) {
    return toText(printf("/api/%s"))(method);
}

const baseUrl = toText(printf("http://localhost"));

export const spaUrl = toText(printf("%s:8081"))(baseUrl);

export const hangfireUrl = "/hangfire";

export const hangfireFullUrl = hangfireUrl;

export class Counter extends Record {
    constructor(Value) {
        super();
        this.Value = (Value | 0);
    }
}

export function Counter$reflection() {
    return record_type("Shared.Counter", [], Counter, () => [["Value", int32_type]]);
}

export class UserInfo extends Record {
    constructor(UserId) {
        super();
        this.UserId = UserId;
    }
}

export function UserInfo$reflection() {
    return record_type("Shared.UserInfo", [], UserInfo, () => [["UserId", string_type]]);
}

export class HeaderCell$1 extends Record {
    constructor(Label, Key) {
        super();
        this.Label = Label;
        this.Key = Key;
    }
}

export function HeaderCell$1$reflection(gen0) {
    return record_type("Shared.HeaderCell`1", [gen0], HeaderCell$1, () => [["Label", string_type], ["Key", gen0]]);
}

export class UserRole extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Admin", "EndUser"];
    }
}

export function UserRole$reflection() {
    return union_type("Shared.UserRole", [], UserRole, () => [[], []]);
}

export class IServerApi extends Record {
    constructor(Counter, UserRoles, GetFlexProducts, GetInjectiveProducts, GetClientStats, GetSettings, SaveSettings) {
        super();
        this.Counter = Counter;
        this.UserRoles = UserRoles;
        this.GetFlexProducts = GetFlexProducts;
        this.GetInjectiveProducts = GetInjectiveProducts;
        this.GetClientStats = GetClientStats;
        this.GetSettings = GetSettings;
        this.SaveSettings = SaveSettings;
    }
}

export function IServerApi$reflection() {
    return record_type("Shared.IServerApi", [], IServerApi, () => [["Counter", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Counter$reflection()]))], ["UserRoles", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(UserRole$reflection())]))], ["GetFlexProducts", lambda_type(SolanaEnvironment$reflection(), lambda_type(Kind$reflection(), lambda_type(string_type, lambda_type(SortOrder$reflection(), lambda_type(Paging$1$reflection(option_type(FlexProductBackendSortField$reflection())), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [FlexProductsPage$reflection()]))))))], ["GetInjectiveProducts", lambda_type(InjectiveEnvironment$reflection(), lambda_type(Paging$1$reflection(option_type(InjectiveHoldingBackendSortField$reflection())), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [InjectiveProductsPage$reflection()])))], ["GetClientStats", lambda_type(Paging$1$reflection(option_type(ClientStatsSortField$reflection())), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [ClientStatPage$reflection()]))], ["GetSettings", lambda_type(SolanaEnvironment$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Settings$reflection()]))], ["SaveSettings", lambda_type(SolanaEnvironment$reflection(), lambda_type(Settings$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))]]);
}

