import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { toString } from "./fable_modules/fable-library.3.7.9/Date.js";
import { map, empty, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.7.9/List.js";
import TableRow from "@material-ui/core/TableRow";
import { Interop_reactApi } from "./fable_modules/Feliz.1.62.0/./Interop.fs.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.9/Util.js";
import { join } from "./fable_modules/fable-library.3.7.9/String.js";
import utils$002Emodule from "./styles/utils.module.scss";
import Pagination from "@material-ui/lab/Pagination";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.62.0/React.fs.js";
import { SolanaEnvironment, Paging$1, SortOrder } from "../../../lib/Domain/Types/Common.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { ClientStatsSortField } from "../../../lib/Domain/Types/ClientStats.fs.js";
import { HeaderCell$1 } from "../../shared/Api.fs.js";
import { ApiHandler$1, useApi } from "./Server.fs.js";
import { singleton } from "./fable_modules/fable-library.3.7.9/AsyncBuilder.js";
import { Col, Row } from "../../../lib/CustomComponents/CustomComponents.fs.js";
import { EnvironmentFlag } from "./EnvironmentFlag.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

export function ClientStatsRowTable(stat) {
    const clientAction = (stat.ClientAction.tag === 1) ? "Withdraw" : "Deposit";
    const children = ofArray([MuiHelpers_createElement(TableCell, [["children", toString(stat.Time, "yyyy-MM-dd HH:mm")]]), MuiHelpers_createElement(TableCell, [["children", clientAction]]), MuiHelpers_createElement(TableCell, [["children", stat.IpAddress]]), MuiHelpers_createElement(TableCell, [["children", stat.TransactionId]]), MuiHelpers_createElement(TableCell, [["children", stat.IpCountryName]]), MuiHelpers_createElement(TableCell, [["children", stat.UserPublicKey.fields[0]]])]);
    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function PagingRow(pagingRowInputProps) {
    let elems;
    const changePageCallback = pagingRowInputProps.changePageCallback;
    const totalPageCount = pagingRowInputProps.totalPageCount;
    const paging = pagingRowInputProps.paging;
    return createElement("div", createObj(ofArray([["className", join(" ", [utils$002Emodule["padding-m"], utils$002Emodule["margin-s-bottom"]])], (elems = [MuiHelpers_createElement(Pagination, [["count", (totalPageCount != null) ? totalPageCount : 1], ["page", paging.PageNumber], ["showFirstButton", true], ["showLastButton", true], ["onChange", (delegateArg0, delegateArg1) => {
        changePageCallback(delegateArg1);
    }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ClientStatsList(clientStatsListInputProps) {
    let elems_5, elems_4, children_1, elems_1, elems_3;
    const user = clientStatsListInputProps.user;
    const patternInput = useFeliz_React__React_useState_Static_1505(new SortOrder(1));
    const sortOrder = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const auth = useAuth0();
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(10);
    const paging = new Paging$1(patternInput_3[0], useFeliz_React__React_useState_Static_1505(1)[0], patternInput_4[0], patternInput_1[0], sortOrder);
    const headerCells = ofArray([new HeaderCell$1("Time", new ClientStatsSortField(0)), new HeaderCell$1("Client Action", new ClientStatsSortField(1)), new HeaderCell$1("Ip Address", new ClientStatsSortField(2)), new HeaderCell$1("Transaction Id", new ClientStatsSortField(5)), new HeaderCell$1("Country", new ClientStatsSortField(3)), new HeaderCell$1("Wallet Key", new ClientStatsSortField(4))]);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(new SolanaEnvironment(1));
    const environment = patternInput_6[0];
    useReact_useEffect_Z101E1A95(useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => useApi(new ApiHandler$1(() => auth.getIdTokenClaims(), (server) => singleton.Delay(() => singleton.Bind(server.GetClientStats(paging), (_arg1) => singleton.Return(_arg1))))), patternInput_2[1]), [environment]);
    switch (data.tag) {
        case 1: {
            return createElement("h1", {
                children: ["Loading"],
            });
        }
        case 3: {
            const value_1 = data.fields[0].message;
            return createElement("p", {
                children: [value_1],
            });
        }
        case 2: {
            return createElement(Row, {
                classes: singleton_1(utils$002Emodule["gap-l"]),
                children: ofArray([createElement(EnvironmentFlag, {
                    environment: environment,
                    setEnvironment: patternInput_6[1],
                }), createElement(Col, {
                    classes: empty(),
                    children: singleton_1(MuiHelpers_createElement(TableContainer, [["className", join(" ", [])], (elems_5 = [MuiHelpers_createElement(Table, [["className", join(" ", [])], (elems_4 = [(children_1 = singleton_1(MuiHelpers_createElement(TableRow, [["className", join(" ", [])], (elems_1 = map((headerCol) => {
                        const children = singleton_1(MuiHelpers_createElement(TableSortLabel, [["children", headerCol.Label], ["onClick", (_arg1_1) => {
                            patternInput_1[1](headerCol.Key);
                            patternInput[1]((sortOrder.tag === 1) ? (new SortOrder(0)) : (new SortOrder(1)));
                        }]]));
                        return MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
                    }, headerCells), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), MuiHelpers_createElement(TableBody, [(elems_3 = map((clientStat) => createElement(ClientStatsRowTable, clientStat), data.fields[0].ClientStats), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])),
                })]),
            });
        }
        default: {
            return null;
        }
    }
}

