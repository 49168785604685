import { Record } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Types.js";
import { record_type, class_type, string_type, bool_type } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Reflection.js";

export class Settings extends Record {
    constructor(HideAllProducts, ExcludedProductsPublicKeys, SettingsDate, Username, WarningMessage, GeneralMessage, FreezeAllDeposits) {
        super();
        this.HideAllProducts = HideAllProducts;
        this.ExcludedProductsPublicKeys = ExcludedProductsPublicKeys;
        this.SettingsDate = SettingsDate;
        this.Username = Username;
        this.WarningMessage = WarningMessage;
        this.GeneralMessage = GeneralMessage;
        this.FreezeAllDeposits = FreezeAllDeposits;
    }
}

export function Settings$reflection() {
    return record_type("Settings.Settings", [], Settings, () => [["HideAllProducts", bool_type], ["ExcludedProductsPublicKeys", string_type], ["SettingsDate", class_type("System.DateTime")], ["Username", string_type], ["WarningMessage", string_type], ["GeneralMessage", string_type], ["FreezeAllDeposits", bool_type]]);
}

